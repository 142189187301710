import React from "react";

import classNames from "@/base/lib/class-names.js";

import text from "@/base/text/index.js";

import TooltipSmall, { TooltipSmallWrapper } from "@/base/components/tooltip-small/index.js";

import Wrapper from "./wrapper.js";
import styles from "./styles.module.css";


const AXIS_X_HEIGHT_IN_REM = 4;
const AXIS_X_VALUE_MARGIN_IN_REM = 6;

const ChartBarCustom = (props) => {
    const getDatasetMaxValue = () => {
        let maxValue = 0;

        for (let i = 0; i < props.data.length; i += 1) {
            const dataset = props.data[i].dataset || [];

            let fullDatasetValue = 0;

            for (let j = 0; j < dataset.length; j += 1) {
                fullDatasetValue += dataset[j].value;
            }

            if (fullDatasetValue > maxValue) {
                maxValue = fullDatasetValue;
            }
        }

        if (props.minX && maxValue < props.minX) {
            maxValue = props.minX;
        }

        return maxValue;
    };

    const getAxisXState = () => {
        const datasetMaxValue = getDatasetMaxValue();

        const axisValues = [];
        const axisGap = datasetMaxValue / props.maxAxisX;

        if (axisGap < 1) {
            for (let i = 0; i <= datasetMaxValue; i += 1) {
                axisValues.push(i);
            }
        } else {
            for (let i = 0; i <= props.maxAxisX; i += 1) {
                axisValues.push(axisGap * i);
            }
        }

        return {
            maxValue: datasetMaxValue,
            axisValues,
        };
    };

    const getBarPartStyle = (data, axisXMaxValue) => {
        const { value, color } = data;

        let height = (value * 100) / axisXMaxValue;

        if (Number.isNaN(height)) {
            height = 0;
        }

        return {
            backgroundColor: color,
            height: `${height}%`,
        };
    };

    const getBarsStyle = () => {
        return {
            paddingBottom: `${AXIS_X_HEIGHT_IN_REM / 2}rem`,
            paddingTop: `${AXIS_X_HEIGHT_IN_REM / 2}rem`,
            width: `calc(100% - ${AXIS_X_VALUE_MARGIN_IN_REM}rem)`,
            left: `${AXIS_X_VALUE_MARGIN_IN_REM}rem`,
        };
    };

    const getBarTooltipStyle = (totalValue, axisXMaxValue) => {
        let barHeightPercent = totalValue * 100 / axisXMaxValue;

        if (Number.isNaN(barHeightPercent)) {
            barHeightPercent = 100;
        }

        return {
            bottom: `calc(${barHeightPercent}% + 1rem)`,
        };
    };

    const getTooltipPosition = (barIndex) => {
        const totalBars = props.data.length;
        const barPosition = (100 * (barIndex + 1)) / totalBars;

        if (barIndex === 0 || barPosition < 30) {
            return "right";
        }

        if (barIndex === totalBars - 1 || barPosition > 70) {
            return "left";
        }

        return "center";
    };

    /* --- */

    const renderAxisX = ({ axisValues, maxValue }) => {
        const reversedAxis = maxValue === 0
            ? [3, 2, 1, 0]
            : [...axisValues].reverse();

        const axis = [];

        for (let i = 0; i < reversedAxis.length; i += 1) {
            let val = reversedAxis[i];

            const isIntVal = Number.isInteger(parseFloat(val)); // eslint-disable-line compat/compat

            if (!isIntVal) {
                val = parseFloat(val).toFixed(1);
            }

            axis.push(
                <div
                    className={styles.axisX}
                    style={{
                        height: `${AXIS_X_HEIGHT_IN_REM}rem`,
                        gridTemplateColumns: `${AXIS_X_VALUE_MARGIN_IN_REM}rem auto`,
                    }}
                >
                    <div className={styles.axisXLabel}>
                        {val}
                    </div>
                    <div className={styles.axisXLine} />
                </div>,
            );
        }

        return (
            <div
                className={styles.axesX}
                onMouseEnter={() => {
                    props.onAxisXHover(true);
                }}
                onMouseLeave={() => {
                    props.onAxisXHover(false);
                }}
            >
                {axis}
            </div>
        );
    };

    const renderBarLabel = (data, index) => {
        let tooltip = null;

        if (data.tooltip) {
            tooltip = (
                <TooltipSmall
                    position={getTooltipPosition(index)}
                >
                    {data.tooltip}
                </TooltipSmall>
            );
        }

        let labelIcon = null;

        if (data.labelIcon && props.isLabelsVertical) {
            const labelIconClassName = classNames({
                [styles.labelIcon]: true,
                [styles.labelIconVertical]: props.isLabelsVertical,
            });

            labelIcon = (
                <div className={labelIconClassName}>
                    {data.labelIcon}
                </div>
            );
        }

        const barLabelContainerClassName = classNames({
            [styles.barLabelContainer]: true,
            [styles.barLabelContainerVertical]: props.isLabelsVertical,
            [styles.barLabelContainerVerticalWithIcon]: !!labelIcon,
        });

        const barLabelContainerStyle = {
            width: `${props.barWidth}rem`,
        };

        const barLabelClassName = classNames({
            [styles.barLabelEllipsis]: props.withLabelEllipsis,
            [props.barLabelClassName]: props.barLabelClassName,
        });

        return (
            <TooltipSmallWrapper
                tooltip={tooltip}
            >
                <div
                    className={barLabelContainerClassName}
                    style={barLabelContainerStyle}
                >
                    {labelIcon}
                    <div className={barLabelClassName}>
                        {data.label || ""}
                    </div>
                </div>
            </TooltipSmallWrapper>
        );
    };

    const renderBarTooltip = (data, totalValue, axisXMaxValue) => {
        if (!data?.barTooltip?.content) {
            return null;
        }

        const isVisible = !!data?.barTooltip?.isVisible;

        if (!isVisible && !data?.barTooltip?.isVisibleOnHover) {
            return null;
        }

        const tooltipContentClassName = data?.barTooltip?.contentClassName || "";

        const contentClassName = classNames({
            [tooltipContentClassName]: !!tooltipContentClassName,
        });

        const tooltipClassName = data?.barTooltip?.className || "";

        const tooltipBarClassName = classNames({
            [styles.barPartTooltipContainer]: true,
            [styles.barPartTooltipContainerVisible]: isVisible,
            [tooltipClassName]: !!tooltipClassName,
        });

        const barTooltipStyle = getBarTooltipStyle(totalValue, axisXMaxValue);

        return (
            <div
                className={tooltipBarClassName}
                style={barTooltipStyle}
            >
                <TooltipSmall
                    className={styles.barPartTooltip}
                    contentClassName={contentClassName}
                    position="center"
                    isArrowUp={false}
                    withOffset={false}
                >
                    {data.barTooltip.content}
                </TooltipSmall>
            </div>
        );
    };

    const renderBarValue = (data, totalValue, axisXMaxValue) => {
        if (!data?.barValue?.value) {
            return null;
        }

        const valueClassName = data?.barValue?.className || "";

        const className = classNames({
            [styles.barValue]: true,
            [valueClassName]: valueClassName,
        });

        let bottomValue = (totalValue * 100) / axisXMaxValue;

        if (Number.isNaN(bottomValue)) {
            bottomValue = 0;
        }

        const bottomOffset = data.barValue?.isOnTop
            ? 0
            : 2.5;

        return (
            <div
                className={className}
                style={{
                    bottom: `calc(${bottomValue}% - ${bottomOffset}rem)`,
                }}
            >
                {data.barValue.value}
            </div>
        );
    };

    const renderBars = (axisXMaxValue) => {
        const bars = [];

        for (let i = 0; i < props.data.length; i += 1) {
            const data = props.data[i];

            const barParts = [];

            let totalValue = 0;

            let isTopBorderRadiusAdded = false;

            for (let j = 0; j < data.dataset.length; j += 1) {
                const d = data.dataset[j];
                const style = getBarPartStyle(d, axisXMaxValue);

                const barPartClassName = classNames({
                    [styles.barPart]: true,
                    [styles.barPartRounded]: d.value > 0 && !isTopBorderRadiusAdded,
                });

                if (d.value > 0) {
                    isTopBorderRadiusAdded = true;
                }

                totalValue += d.value;

                barParts.push(
                    <div
                        style={style}
                        className={barPartClassName}
                    />,
                );
            }

            const barWrapperClassName = classNames({
                [styles.barWrapper]: true,
                [styles.barWrapperWithTooltipOnHover]: !!data?.barTooltip?.isVisibleOnHover,
            });

            const barClassName = classNames({
                [styles.bar]: true,
                [props.barClassName]: props.barClassName,
            });

            bars.push(
                <div className={barWrapperClassName}>
                    <div
                        className={barClassName}
                        style={{
                            width: `${props.barWidth}rem`,
                        }}
                    >
                        {barParts}
                    </div>
                    {renderBarValue(data, totalValue, axisXMaxValue)}
                    {renderBarTooltip(data, totalValue, axisXMaxValue)}
                </div>,
            );
        }

        const barsStyle = getBarsStyle();

        return (
            <div
                className={styles.bars}
                style={barsStyle}
            >
                {bars}
            </div>
        );
    };

    const renderMessage = (message) => {
        if (!message) {
            return null;
        }

        const barsStyle = getBarsStyle();

        return (
            <div
                className={styles.chartMessage}
                style={barsStyle}
            >
                {message}
            </div>
        );
    };

    const renderBarsLabels = () => {
        const labels = [];

        for (let i = 0; i < props.data.length; i += 1) {
            const data = props.data[i];

            labels.push(renderBarLabel(data, i));
        }

        const labelsStyle = {
            paddingLeft: `${AXIS_X_VALUE_MARGIN_IN_REM}rem`,
        };

        const labelsClassName = classNames({
            [styles.labels]: true,
            [props.labelsClassName]: props.labelsClassName,
        });

        return (
            <div
                className={labelsClassName}
                onMouseEnter={() => {
                    props.onLabelHover(true);
                }}
                onMouseLeave={() => {
                    props.onLabelHover(false);
                }}
                style={labelsStyle}
            >
                {labels}
            </div>
        );
    };

    const renderContent = () => {
        const axisXState = getAxisXState();

        let message = "";

        if (props.message) {
            message = props.message;
        } else if (axisXState.maxValue === 0) {
            message = text.noData;
        }

        return (
            <>
                <div className={styles.chart}>
                    {renderAxisX(axisXState)}
                    {renderBars(axisXState.maxValue)}
                    {renderMessage(message)}
                </div>
                {renderBarsLabels()}
            </>
        );
    };

    /* --- */

    const containerClassName = classNames({
        [styles.container]: true,
        [props.containerClassName]: props.containerClassName,
    });

    return (
        <div className={containerClassName}>
            {renderContent()}
        </div>
    );
};

ChartBarCustom.defaultProps = {
    data: [],
    message: "",
    containerClassName: "",
    barLabelClassName: "",
    labelsClassName: "",
    barClassName: "",
    barLabel: "",
    minX: null,
    maxAxisX: 5,
    barWidth: 1.6,
    onLabelHover: () => { },
    onAxisXHover: () => { },
    withLabelEllipsis: true,
    isLabelsVertical: true,
};

export const ChartBarCustomWrapper = Wrapper;
export default ChartBarCustom;
