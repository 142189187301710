import text from "@/base/text/index.js";


const loadSiteDate = (services) => {
    const { api, actions } = services;

    return async (dispatch) => {
        const res = await api.site.getSiteDate();

        let date = "";
        let error = "";

        if (res.ok) {
            date = res.date;

            dispatch(actions.info.setSiteDate({
                date,
            }));
        } else {
            error = res.error || text.error;
        }

        return {
            date,
            error,
        };
    };
};

export default {
    loadSiteDate,
};
