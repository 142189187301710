import React from "react";

import classNames from "@/base/lib/class-names.js";

import styles from "./styles.module.css";


const IconJumping = (props) => {
    const iconClassName = classNames({
        [styles.icon]: true,
        [props.className]: !!props.className,
    });

    return (
        <div className={iconClassName}>
            {props.children}
        </div>
    );
};

IconJumping.defaultProps = {
    className: "",
    children: null,
};

export default IconJumping;
