import storage from "@/base/lib/storage/index.js";

import Urls from "@/base/project/urls.js";

import actions from "@/base/store/actions.js";
import actionsUser from "@/base/actions/user.js";
import actionsPolls from "@/base/actions/polls.js";

import monitorCommands from "@/app/monitors/commands.js";

import app from "@/app/app.js";
import api from "@/app/api.js";
import events from "@/app/events.js";


const MONITOR_COMMANDS = monitorCommands.getMonitorCommands();

function getStudentCallbacks(store) {
    const services = { api, actions, events, storage };

    return {
        setUserBadEmail() {
            store.dispatch(actions.user.setIsBadEmail(true));
        },

        setStoryRating(data) {
            store.dispatch(actions.juiceStories.setStoryInfoStoryRate(data));
        },

        setExtraJuiceRating(data) {
            store.dispatch(actions.juiceStories.setStoryInfoStoryExtraJuiceRate(data));
        },

        setAchievements(data) {
            store.dispatch(actions.studentAchievements.setAchievementValueByType(data));
        },

        setQuizAnswered(data) {
            store.dispatch(actions.juiceStories.setStoryInfoStoryAnswer(data));
        },

        setDailyJuiceStoryOpened(data) {
            store.dispatch(actions.juiceStories.setStoryInfoStoryOpened(data));
        },

        setDailyJuiceStoryVideoViewed(data) {
            store.dispatch(actions.juiceStories.setStoryInfoStoryVideoViewed(data));
        },

        setDailyJuiceStoryPollAnswered(data) {
            store.dispatch(actions.juiceStories.setStoryInfoStoryPollAnswerV2(data));
            store.dispatch(actionsPolls.loadPollResultById(services, data));
        },

        userLogoutBySession(data) {
            store.dispatch(actionsUser.signOutBySession(services, data));
        },

        /* --- */

        onUpdate(data) {
            if (data.cmd === MONITOR_COMMANDS.USER_EMAIL_BAD_DETECTED) {
                this.setUserBadEmail();
            } else if (data.cmd === MONITOR_COMMANDS.STORY_RATING_UPDATED) {
                this.setStoryRating(data.payload || {});
            } else if (data.cmd === MONITOR_COMMANDS.DJ_STORY_OPENED) {
                this.setDailyJuiceStoryOpened(data.payload || {});
            } else if (data.cmd === MONITOR_COMMANDS.DJ_STORY_VIDEO_VIEWED) {
                this.setDailyJuiceStoryVideoViewed(data.payload || {});
            } else if (data.cmd === MONITOR_COMMANDS.DJ_STORY_POLL_ANSWERED) {
                this.setDailyJuiceStoryPollAnswered(data.payload || {});
            } else if (data.cmd === MONITOR_COMMANDS.EXTRA_JUICE_RATING_UPDATED) {
                this.setExtraJuiceRating(data.payload || {});
            } else if (data.cmd === MONITOR_COMMANDS.ACHIEVEMENTS_UPDATED) {
                this.setAchievements(data.payload || {});
            } else if (data.cmd === MONITOR_COMMANDS.QUIZ_ANSWERED) {
                this.setQuizAnswered(data.payload || {});
            } else if (data.cmd === MONITOR_COMMANDS.USER_LOGOUT) {
                this.userLogoutBySession(data.payload || {});
            } else {
                // eslint-disable-next-line no-console
                console.log("[WS]: Unknown command ", data);
            }
        },

        onUpdateAll() { },

        onReconnectedAfterOffline() {
            const state = store.getState();
            const navigation = state.navigation || {};
            const urlParams = navigation.params || {};
            const pathname = navigation.location?.pathname || "";

            if (Urls.isDailyJuices(pathname)) {
                app.actions.student.dailyJuices.loadJuiceStoriesInfoById({
                    juiceId: urlParams.juiceId || "",
                });
            }

            app.actions.student.achievements.loadOrReloadStudentAchievements();
        },
    };
}

export default {
    getStudentCallbacks,
};
