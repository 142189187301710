import date from "@/base/lib/date.js";

import Grades from "@/base/project/grades.js";


const getJuiceByGrade = (juices, grades) => {
    if (!juices) {
        return null;
    }

    for (let i = 0; i < juices.length; i += 1) {
        const juice = juices[i];
        const gs = Grades.getGradesRangeByGrade(juice?.grades?.[0]);

        if (gs === grades) {
            return juice;
        }
    }

    return null;
};

export const filterJuicesByGrades = (juices, grades) => {
    if (!grades) {
        return [...juices];
    }

    return juices.filter((j) => {
        if (!j.grades) {
            return false;
        }

        if (grades === "G5-6") {
            return j.grades.indexOf("G5") !== -1
                || j.grades.indexOf("G6") !== -1;
        }

        if (grades === "G7-8") {
            return j.grades.indexOf("G7") !== -1
                || j.grades.indexOf("G8") !== -1;
        }

        if (grades === "G9-10") {
            return j.grades.indexOf("G9") !== -1
                || j.grades.indexOf("G10") !== -1;
        }

        if (grades === "G11-12") {
            return j.grades.indexOf("G11") !== -1
                || j.grades.indexOf("G12") !== -1;
        }

        return false;
    });
};

const getJuiceDayDate = (juiceDate) => {
    return (juiceDate || "").split(" ")[0] || "";
};

const getJuiceByDate = (juices, dateStr) => {
    const ids = Object.keys(juices);

    for (let i = 0; i < ids.length; i += 1) {
        const juiceId = ids[i];
        const j = juices[juiceId];
        const jDate = getJuiceDayDate(j.juiceDate);

        if (jDate === dateStr) {
            return j;
        }
    }

    return null;
};


const getIsJuiceIdOrDate = (value) => {
    const isDate = /\d\d\d\d-\d\d-\d\d/.test(value);

    if (!isDate) {
        return true;
    }

    if (date.newDate(value)) {
        return false;
    }

    return true;
};

const getJuiceId = (juiceIdOrDate, juices) => {
    const isJuiceById = getIsJuiceIdOrDate(juiceIdOrDate);

    if (isJuiceById) {
        return {
            isJuiceById,
            juiceId: juiceIdOrDate,
        };
    }

    const juice = getJuiceByDate(juices, juiceIdOrDate);

    return {
        isJuiceById,
        juiceId: juice ? String(juice.id) : "",
    };
};

const sortJuiceDatesASC = (dates) => {
    if (dates && dates.length > 0) {
        return dates.map((val) => {
            return {
                ...val,
                dateValue: date.newDate(val.date),
            };
        }).sort((a, b) => a.dateValue - b.dateValue);
    }

    return [];
};

const getJuiceGrades = (juice) => {
    if (juice?.grades?.length > 0) {
        return juice.grades.join("-");
    }

    const maxGrade = Grades.getMaxGradeValue();

    return maxGrade;
};

const getJuicesDates = (datesByMonths) => {
    const dates = [];
    const keys = Object.keys(datesByMonths)

    for (let i = 0; i < keys.length; i += 1) {
        const range = keys[i];
        const datesByRange = datesByMonths[range].data || [];

        dates.push(...datesByRange);
    }

    return dates;
};


export default {
    getJuiceByGrade,

    getJuiceDayDate,
    getJuiceByDate,
    getJuiceId,

    sortJuiceDatesASC,

    getJuiceGrades,

    getJuicesDates,
};
