// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".p6MjURL90MabWYAu5gTX {\n    height: 1.2rem;\n\n    border-radius: 0.4rem;\n\n    opacity: 0.3;\n\n    background: var(--grey);\n}\n\n.V2klNGXBwVL565qS8iPu {\n    animation: v9oXXBP2uf5Zcc_7WcuF 2s infinite;\n}\n\n.UkDQo1etUiQZMW1UR5uG {\n    height: 1rem;\n    width: 12rem;\n}\n\n.JDBdczf_vmdAeEPAlv2f {\n    border-radius: 50%;\n    height: 1rem;\n    width: 1rem;\n}\n\n.UsuGILSnhd1hR0BpYj2a {\n    border-radius: 0;\n    height: 2rem;\n    width: 10rem;\n}\n\n@keyframes v9oXXBP2uf5Zcc_7WcuF {\n    0% {\n        opacity: 0.3;\n    }\n    50% {\n        opacity: 0.1;\n    }\n    100% {\n        opacity: 0.3;\n    }\n}", "",{"version":3,"sources":["webpack://./base/components/skeleton/styles.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;;IAEd,qBAAqB;;IAErB,YAAY;;IAEZ,uBAAuB;AAC3B;;AAEA;IACI,2CAA8B;AAClC;;AAEA;IACI,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI;QACI,YAAY;IAChB;IACA;QACI,YAAY;IAChB;IACA;QACI,YAAY;IAChB;AACJ","sourcesContent":[".skeleton {\n    height: 1.2rem;\n\n    border-radius: 0.4rem;\n\n    opacity: 0.3;\n\n    background: var(--grey);\n}\n\n.skeletonAnimation {\n    animation: opacity 2s infinite;\n}\n\n.text {\n    height: 1rem;\n    width: 12rem;\n}\n\n.circle {\n    border-radius: 50%;\n    height: 1rem;\n    width: 1rem;\n}\n\n.rect {\n    border-radius: 0;\n    height: 2rem;\n    width: 10rem;\n}\n\n@keyframes opacity {\n    0% {\n        opacity: 0.3;\n    }\n    50% {\n        opacity: 0.1;\n    }\n    100% {\n        opacity: 0.3;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skeleton": "p6MjURL90MabWYAu5gTX",
	"skeletonAnimation": "V2klNGXBwVL565qS8iPu",
	"opacity": "v9oXXBP2uf5Zcc_7WcuF",
	"text": "UkDQo1etUiQZMW1UR5uG",
	"circle": "JDBdczf_vmdAeEPAlv2f",
	"rect": "UsuGILSnhd1hR0BpYj2a"
};
export default ___CSS_LOADER_EXPORT___;
