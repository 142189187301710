// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wYA9kwA9TkCuAq7_psCs {\n    width: 3.5rem;\n    height: 3.5rem;\n\n    position: relative;\n\n    border-radius: 5rem;\n\n    cursor: help;\n}\n\n.eMJVFHFmJ7s7j3dhbknr {\n    width: 2rem;\n    height: 2rem;\n}\n\n.KZTdzmGUca13O1I0OUeM {\n    background-color: var(--tooltip-sky-bg-color);\n}\n\n.OPG4dpISS3lrQGk30fbL {\n    position: relative;\n    width: 100%;\n    height: 100%;\n}\n\n/* --- */\n\n.zcIctJiPs3WK_IcJNuG4 {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n\n    width: 1.7rem;\n    height: 1.7rem;\n}\n\n", "",{"version":3,"sources":["webpack://./base/components/tooltip-info-with-icon/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,cAAc;;IAEd,kBAAkB;;IAElB,mBAAmB;;IAEnB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,6CAA6C;AACjD;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA,QAAQ;;AAER;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;;IAEhC,aAAa;IACb,cAAc;AAClB","sourcesContent":[".container {\n    width: 3.5rem;\n    height: 3.5rem;\n\n    position: relative;\n\n    border-radius: 5rem;\n\n    cursor: help;\n}\n\n.containerSmall {\n    width: 2rem;\n    height: 2rem;\n}\n\n.containerSkySelected {\n    background-color: var(--tooltip-sky-bg-color);\n}\n\n.iconJumpingContainer {\n    position: relative;\n    width: 100%;\n    height: 100%;\n}\n\n/* --- */\n\n.icon {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n\n    width: 1.7rem;\n    height: 1.7rem;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "wYA9kwA9TkCuAq7_psCs",
	"containerSmall": "eMJVFHFmJ7s7j3dhbknr",
	"containerSkySelected": "KZTdzmGUca13O1I0OUeM",
	"iconJumpingContainer": "OPG4dpISS3lrQGk30fbL",
	"icon": "zcIctJiPs3WK_IcJNuG4"
};
export default ___CSS_LOADER_EXPORT___;
