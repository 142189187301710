import text from "@/base/text/index.js";


const loadDailyJuicesByPage = (services, params) => {
    const { api, actions } = services;

    return async (dispatch) => {
        dispatch(actions.juices.setJuicesLoading());

        const res = await api.dailyJuices.getJuicesByPage({
            session: params.session,
            grades: params.grades || "",
            page: params.page,
        });

        let juices = [];
        let error = null;

        if (res.ok) {
            juices = res.juices;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.juices.setJuices({
            hasMorePages: res?.hasMore || false,
            juices,
            error,
        }));
    };
};

// TODO: remove later
const loadJuicesDatesByGrade = (services, params, callbacks) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.juices.clearJuicesDates());

        const res = await api.dailyJuices.getJuicesDatesByGrade({
            session: state.user.session,
            grades: params.grade,
        });

        let dates = [];
        let error = "";

        if (res.ok) {
            dates = (res?.juices || []).map((juice) => ({
                date: juice.date,
                juiceId: juice.id,
            }));
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.juices.setJuicesDates({
            dates,
        }));

        // TODO: remove later
        if (callbacks?.onLoad) {
            callbacks.onLoad(dates, res);
        }

        return {
            dates,
            error,
        };
    };
};

const loadJuicesDates = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.juices.clearJuicesDates());

        const res = await api.dailyJuices.getJuicesDates({
            session: state.user.session,
        });

        if (!res.ok) {
            return;
        }

        const dates = (res.juices || []).map((juice) => ({
            date: juice.date,
            juiceId: juice.id,
        }));

        dispatch(actions.juices.setJuicesDates({
            dates,
        }));
    };
};


const loadJuiceById = (services, params) => {
    const { api, actions } = services;
    const { juiceId } = params;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.juices.setJuiceLoading({
            juiceId,
        }));

        const res = await api.dailyJuices.getJuice({
            session: state.user.session,
            juiceId,
        });

        let juice = null;
        let error = "";

        if (res.ok) {
            juice = res.data || null;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.juices.setJuice({
            juiceId,
            juice,
            error,
        }));

        return {
            juice,
            error,
        };
    };
};

const loadUnpublishedJuiceById = (services, params) => {
    const { api, actions } = services;
    const { juiceId } = params;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.juices.setJuiceLoading({
            juiceId,
        }));

        const res = await api.dailyJuices.getUnpublishedJuice({
            session: state.user.session,
            juiceId,
        });

        let juice = null;
        let error = "";

        if (res.ok) {
            juice = res.data || null;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.juices.setJuice({
            juiceId,
            juice,
            error,
        }));

        return {
            juice,
            error,
        };
    };
};

export default {
    loadDailyJuicesByPage,
    loadJuicesDatesByGrade,
    loadJuicesDates,
    loadJuiceById,
    loadUnpublishedJuiceById,
};
