import React, {
    useRef,
    useEffect,
    useState,
    useCallback,
    Suspense,
    useMemo,
} from "react";

import {
    Outlet,
    useLocation,
    useParams,
    useNavigate,
    matchPath,
} from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import text from "@/base/text/index.js";

import User from "@/base/project/user.js";
import Classes from "@/base/project/classes.js";
import Achievements from "@/base/project/achievements.js";
import Grades from "@/base/project/grades.js";

import urls from "@/base/lib/urls.js";
import document from "@/base/lib/document.js";
import storage from "@/base/lib/storage/index.js";
import scroll from "@/base/lib/scroll.js";
import actions from "@/base/store/actions.js";
import appActions from "@/base/actions/app.js";
import actionsUser from "@/base/actions/user.js";
import actionsTeacher from "@/base/actions/teacher.js";
import actionsStudent from "@/base/actions/student.js";

import useDimensions from "@/base/hooks/use-dimensions/index.js";
import usePopup from "@/base/hooks/use-popup/index.js";

import StripeContext from "@/base/context/stripe/index.js";

import ErrorBoundary from "@/base/components/error-boundary/index.js";
import ErrorBoundaryWithValue from "@/base/components/error-boundary-with-value/index.js";
import RequestLoader from "@/base/components/request-loader/index.js";
import PopupConfirmError, { usePopupConfirmError } from "@/base/components/popup-confirm-error";

import PopupConfirmClassesLimitExceeded from "@/base/business/popup-confirm-classes-limit-exceeded/index.js";
import PopupEmailChange from "@/base/business/popup-email-change/index.js";
import PopupNewPassword from "@/base/business/popup-new-password/index.js";
import PopupConfirmMissingClassesEndDate from "@/base/business/popup-confirm-missing-classes-end-date/index.js";
import PopupConfirmClassesEndDate, {
    usePopupConfirmClassesEndDate,
} from "@/base/business/popup-confirm-classes-end-date/index.js";
import Snackbar, { useSnackbar } from "@/base/business/snackbar/index.js";

import LayoutContainer from "@/app/containers/layout/index.js";
import PopupBadEmail from "@/app/containers/popup-bad-email/index.js";
import PopupMessages from "@/app/containers/popup-messages/index.js";
import PopupNotification from "@/app/containers/popup-notification/index.js";
import PopupRestoreClasses from "@/app/containers/popup-restore-classes/index.js";
import TeacherPopupWelcome from "@/app/containers/teacher-popup-welcome/index.js";
import UserPopupAccount from "@/app/containers/user-popup-account/index.js";
import UserPopupConfirmExpiration from "@/app/containers/user-popup-confirm-expiration/index.js";

import app from "@/app/app.js";
import settings from "@/app/settings.js";
import api from "@/app/api.js";


const storeSelector = (state) => ({
    libs: state.app.libs,
    version: state.app.version,
    isIframeMode: state.app.isIframeMode,
    isSocketMonitorOnline: state.monitors.isSocketMonitorOnline,
    theme: state.app.theme,
    session: state.user.session,
    user: state.user.user,
    isUserLoaded: state.user.isUserLoaded,
    isTeacherWelcomePopupOpen: state.user.isTeacherWelcomePopupOpen,
    teacher: state.teacher,
    studentAchievements: state.studentAchievements.achievements,
    siteDate: state.info.siteDate,
    location: state.navigation.location,
    docTitle: state.navigation.docTitle,
    notifications: state.notifications.notifications,
    notificationsById: state.notifications.notificationsById,
    studentGrades: state.student.grades,
    isGradesLoading: state.user.isUserGradesLoading,
    popupBadEmail: state.uiPopups.badEmail,
    isVisibleRestoreClasses: state.uiPopups.isVisibleRestoreClasses,
    classesLimitInfo: state.user.classesLimitInfo,
});

const RootLayout = () => {
    const [isVisibleAccountPopup, setIsVisibleAccountPopup] = useState(false);

    const loc = useLocation();
    const params = useParams();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const store = useSelector(storeSelector);

    const dimensions = useDimensions();
    const snackbar = useSnackbar();

    const popupMissingClassesEndDate = usePopup();
    const popupConfirmClassesEndDate = usePopupConfirmClassesEndDate();
    const popupConfirmError = usePopupConfirmError();
    const popupNotification = usePopup();
    const popupClassesLimit = usePopup();
    const emailPopup = usePopup();
    const newPasswordPopup = usePopup();

    const roles = useMemo(() => ({
        isTeacher: User.hasRoleTeacher(store.user),
        isParent: User.hasRoleParent(store.user),
        isStudent: User.hasRoleStudent(store.user),
        isDistrictAdmin: User.hasRoleDistrictAdmin(store.user),
    }), [store.user.roles]);

    const userInitials = User.getFirstInitial(store.user);

    const isMobile600 = dimensions.width <= 600;

    const isSignUpCompleted = store.user?.isSignUpCompleted || false;

    /* --- */

    // NOTE: don't touch "function" if you don't understand js context
    const stripeCtx = {
        stripe: null,
        loadStripe: null,

        isInitialized: function isInitialized() {
            return this.loadStripe !== null && this.stripe !== null; // eslint-disable-line react/no-this-in-sfc, max-len
        },

        initStripe: function initStripe() {
            // NOTE: check webpack for global STRIPE_PUBLIC_KEY variable
            const stripePublicKey = STRIPE_PUBLIC_KEY; // eslint-disable-line no-undef

            if (!this.stripe && this.loadStripe) { // eslint-disable-line react/no-this-in-sfc
                this.stripe = this.loadStripe(stripePublicKey); // eslint-disable-line react/no-this-in-sfc, max-len
                dispatch(actions.app.setLibStripeInitLoaded());
            }
        },

        loadStripeLib: async function loadStripeLib() {
            if (this.loadStripe) { // eslint-disable-line react/no-this-in-sfc
                return;
            }

            try {
                const { loadStripe } = await import("@stripe/stripe-js");

                this.loadStripe = loadStripe; // eslint-disable-line react/no-this-in-sfc

                dispatch(actions.app.setLibStripeLoaded());
            } catch (err) {
                console.error(err); // eslint-disable-line no-console

                dispatch(actions.app.setLibStripeError({
                    error: "Cannot load Stripe Payment",
                }));
            }
        },
    };

    const stripeCtxRef = useRef(stripeCtx);

    /* --- */

    const shouldShowMissingEndDatePopup = () => {
        if (roles.isParent) {
            return false;
        }
        const ignorePaths = [
            "/subscribe",
            "/class-settings",
        ];

        for (let i = 0; i < ignorePaths.length; i += 1) {
            const path = ignorePaths[i];

            if (loc.pathname.indexOf(path) === 0) {
                return false;
            }
        }

        return true;
    };

    /* --- */

    const getIsVisibleMobileHeader = () => {
        if (store.isIframeMode) {
            return false;
        }

        if (dimensions.width >= 901) {
            return true;
        }

        for (let i = 0; i < settings.mobileHeaderHiddenPaths.length; i += 1) {
            const path = settings.mobileHeaderHiddenPaths[i];
            const match = matchPath(path, loc.pathname);

            if (match) {
                return false;
            }
        }

        return true;
    };

    /* --- */

    const getClassesLimitInfo = useCallback(() => {
        const isLoaded = store.classesLimitInfo.isLoaded
            && !store.classesLimitInfo.error;

        const isVisible = isLoaded
            && Classes.isClassesLimitExceeded(store.classesLimitInfo.data);

        return {
            open: popupClassesLimit.open,
            isVisible,
        };
    }, [store.classesLimitInfo]);

    /* --- */

    const getHeaderBouncingNotifications = () => {
        if (!roles.isTeacher && !roles.isParent) {
            return {};
        }

        const ns = {};

        if (Classes.hasBounceNotification(store.teacher.classes)) {
            ns.Class = text.usersInvalidEmail;
        }

        return ns;
    };

    /* --- */

    const loadNotifications = () => {
        app.actions.common.notifications.loadNotifications();
    };

    const onNotificationMarkAsRead = useCallback(async (id, callbacks) => {
        const { error } = await app.actions.common.notifications.setNotificationViewed({ id });

        if (error) {
            popupConfirmError.open(error);
        }

        callbacks.setLoaded();
    }, []);

    const getNotifications = () => {
        return {
            data: store.notifications.data || [],
            error: store.notifications.error || "",
            onSelect: (id) => {
                popupNotification.setData({ id });
                popupNotification.open();
            },
            onMarkAsRead: onNotificationMarkAsRead,
            onLoadMore: loadNotifications,
            hasMore: store.notifications.hasMore || false,
            isLoading: store.notifications.isLoading || false,
            isGlobalCloseDisabled: popupNotification.state.isOpen,
        };
    };

    /* --- */

    const getAchievements = () => {
        if (!roles.isStudent
            || store.studentAchievements?.isLoading
            || store.studentAchievements?.error) {
            return null;
        }

        const achievementsData = store.studentAchievements?.data || [];

        return Achievements.getAchievementValues(achievementsData);
    };

    /* --- */

    const onStudentChangeGrade = async (grade) => {
        const { error } = await app.actions.student.grades.setStudentGrade({ grade });

        if (error) {
            popupConfirmError.open(error);
        }
    };

    const getGradesSettings = useCallback(() => {
        const { studentGrades, user } = store;

        const options = (studentGrades.data || []).map(({ name, label }) => ({
            value: name,
            label,
            label2: studentGrades.pendingGrade === name ? "Pending" : "",
        }));

        let selected = null;

        if (user?.grades) {
            selected = Grades.getOneGradeRangeByGrades(user.grades);
        }

        const isVisible = studentGrades.isLoaded
            && studentGrades.isAdjustmentAllowed
            && !studentGrades.error;

        return {
            optionsLabel: studentGrades.label,
            selected,
            options,
            onSelect: onStudentChangeGrade,
            isMobile: isMobile600,
            isLoading: store.isGradesLoading,
            isVisible,
        };
    }, [
        store.user,
        store.studentGrades,
        store.isGradesLoading,
        isMobile600,
    ]);

    /* --- */

    const loadTeacherClasses = (userSession, onFinish) => {
        dispatch(actionsTeacher.loadClasses(
            { api, storage, actions },
            {
                session: userSession,
                onFinish,
            },
        ));
    };

    const loadTeacherClassesAndShowEndDate = async () => {
        const classes = await dispatch(actionsTeacher.loadClasses(
            { api, storage, actions },
            { session: store.session },
        ));

        const classesWithMissingEndDate = [];
        const classesToRemind = [];

        for (let i = 0; i < classes.length; i += 1) {
            const cl = classes[i];

            if (!cl.isLmsClass) {
                if (!cl.endDate) {
                    classesWithMissingEndDate.push(cl);
                }

                if (cl.isEndDateSoon) {
                    classesToRemind.push(cl);
                }
            }
        }

        if (classesWithMissingEndDate.length
            && shouldShowMissingEndDatePopup()) {
            popupMissingClassesEndDate.setData({
                classes: classesWithMissingEndDate,
            });
            popupMissingClassesEndDate.open();
        } else if (classesToRemind.length) {
            popupConfirmClassesEndDate.open(classesToRemind);
        }
    };

    const loadClasses = () => {
        if (settings.features.END_DATE && User.isTypeRegular(store.user) && !roles.isParent) {
            loadTeacherClassesAndShowEndDate();
            return;
        }

        loadTeacherClasses(store.session);
    };

    const loadUserOnly = async () => {
        const res = await app.actions.common.user.loadUser();

        if (res.isError) {
            navigate("/sign-in");
        }
    };

    const loadUser = async () => {
        const res = await app.actions.common.user.loadUser();

        if (res.isError && res.isRedirect) {
            navigate("/sign-in");
            return;
        }

        app.actions.common.user.loadReadingMode();
    };

    /* --- */

    const onError = (error) => {
        if (error) {
            const userSession = storage.session.loadSession()
                || storage.local.loadSession();

            api.error.sendError({
                session: userSession,
                error: {
                    ...error,
                    url: store.location.pathname,
                },
            });
        }
    };

    const onSubscribe = () => {
        navigate("/subscribe");
    };

    const onSignOut = () => {
        app.actions.common.user.signOut();
        navigate("/sign-in");
    };

    /* --- */

    const onAutomaticThemeChange = useCallback((isAutomatic) => {
        dispatch(appActions.setAutomaticTheme({ actions, storage }, {
            isAutomatic,
        }));
    }, []);

    const onThemeChange = useCallback((theme) => {
        dispatch(appActions.setTheme({ actions, storage }, {
            theme,
        }));
    }, []);

    /* --- */

    const onOpenEmailEditPopup = () => {
        emailPopup.open();
    };

    const onCloseEmailChangePopup = () => {
        emailPopup.close();
    };

    const onSaveNewEmail = async (value) => {
        emailPopup.setIsSaving();

        const res = await api.user.updateEmail({
            session: store.session,
            email: value,
        });

        let message = "";

        if (res.ok) {
            message = text.emailUpdatedSuccess;
            emailPopup.close();

            loadUserOnly();
        } else {
            message = res.error || text.error;
            emailPopup.setMessage(message);
        }

        snackbar.add({
            message,
        });
    };

    /* --- */

    const onOpenNewPasswordPopup = () => {
        newPasswordPopup.open();
    };

    const onCloseNewPasswordPopup = () => {
        newPasswordPopup.close();
    };

    const onSaveNewPassword = async (value) => {
        newPasswordPopup.setIsSaving();

        const res = await api.user.changePassword({
            session: store.session,
            password: value,
        });

        let message = "";

        if (res.ok) {
            message = text.passwordChanged;
            newPasswordPopup.close();
        } else {
            message = res.error || text.error;
            newPasswordPopup.setMessage(message);
        }

        snackbar.add({
            message,
        });
    };

    /* --- */

    const onContactSupport = () => {
        const supportLink = `${settings.landingSite.domain}${settings.landingSite.routeSupport}`
        urls.openUrl(supportLink);
    };

    const onManageAccount = () => {
        setIsVisibleAccountPopup(true);
    };

    /* --- */

    const onPopupWelcomeClose = () => {
        scroll.scrollToTop();

        dispatch(actionsUser.closeTeacherWelcomePopup({ actions }));

        loadClasses();

        navigate("/dashboard");
    };

    /* --- */

    const loadStudentAchievements = useCallback(() => {
        dispatch(actionsStudent.loadStudentAchievements({ api, actions }));
    }, []);

    const loadStudentGrades = useCallback(() => {
        app.actions.student.grades.loadStudentGrades();
    }, []);

    /* --- */

    useEffect(() => {
        loadUser();
    }, []);

    useEffect(() => {
        if (!roles.isStudent) {
            return;
        }

        loadStudentGrades();
        loadStudentAchievements();
    }, [roles.isStudent]);

    useEffect(() => {
        if (!settings.features.CLASSES_LIMIT || !isSignUpCompleted) {
            return;
        }

        if (!(roles.isTeacher || roles.isDistrictAdmin)) {
            return;
        }

        app.actions.common.user.loadUserClassesLimitInfo();
    }, [
        roles.isTeacher,
        roles.isDistrictAdmin,
        isSignUpCompleted,
    ]);

    useEffect(() => {
        const isBadEmail = store.user?.isBadEmail || false;

        if (!isBadEmail || store.popupBadEmail.isVisible) {
            return;
        }

        dispatch(actions.uiPopups.openBadEmail());
    }, [store.user]);

    useEffect(() => {
        if (!store.session) {
            return;
        }

        loadNotifications();

        app.actions.common.translation.loadLanguagesOnce();
    }, [store.session]);

    useEffect(() => {
        if (!store.isUserLoaded
            || !store.session
            || !(roles.isTeacher || roles.isParent)
            || store.isTeacherWelcomePopupOpen) {
            return;
        }

        const isUserRegularAndWithoutPlan = User.isTypeRegular(store.user)
            && !User.isLegacy(store.user)
            && !User.hasPlan(store.user);

        if (!isSignUpCompleted
            || isUserRegularAndWithoutPlan) {
            loadTeacherClasses(store.session);

            dispatch(actionsUser.openTeacherWelcomePopup({
                actions,
            }));
            return;
        }

        loadClasses();
    }, [
        store.session,
        store.isUserLoaded,
    ]);

    useEffect(() => {
        dispatch(actions.device.setDimensions(dimensions));
    }, [dimensions]);

    useEffect(() => {
        // NOTE: it is triggered AFTER url changed and view changed too
        dispatch(actions.navigation.setLocation({
            location: loc,
            params,
        }));
    }, [
        loc.pathname,
        loc.search,
    ]);

    useEffect(() => {
        let title = settings.appName;

        if (store.docTitle) {
            title += ` - ${store.docTitle}`;
        }

        document.setTitle(title);
    }, [store.docTitle]);

    /* --- */

    const renderTeacherWelcomePopup = () => {
        return (
            <TeacherPopupWelcome
                onTakeTour={() => {
                    loadTeacherClasses(store.session);
                }}
                onExploreOnMyOwn={onPopupWelcomeClose}
                onCloseTourVideo={onPopupWelcomeClose}
            />
        );
    };

    const renderPopupBadEmail = () => {
        return (
            <PopupBadEmail
                onClose={() => {
                    dispatch(actions.uiPopups.clearPopups());
                }}
            />
        );
    };

    const renderClassEndDateReminderPopup = () => {
        return (
            <PopupConfirmClassesEndDate
                classes={popupConfirmClassesEndDate.state.classes}
                onClose={popupConfirmClassesEndDate.close}
            />
        );
    };

    const renderPopupMissingClassesEndDate = () => {
        return (
            <PopupConfirmMissingClassesEndDate
                classes={popupMissingClassesEndDate.state.data.classes}
                onAdd={() => {
                    navigate("/class-settings");
                    popupMissingClassesEndDate.close();
                }}
            />
        );
    };

    const renderEditEmailPopup = () => {
        return (
            <PopupEmailChange
                defaultValue={store.user.email || ""}
                isSaving={emailPopup.state.isSaving}
                error={emailPopup.state.error}
                onSave={onSaveNewEmail}
                onClose={onCloseEmailChangePopup}
            />
        );
    };

    const renderNewPasswordPopup = () => {
        return (
            <PopupNewPassword
                message={newPasswordPopup.state.message}
                isSubmitted={newPasswordPopup.state.isSubmitted}
                isSaving={newPasswordPopup.state.isSaving}
                passwordMinLength={settings.password.minLength}
                onSave={onSaveNewPassword}
                onClose={onCloseNewPasswordPopup}
            />
        );
    };

    const renderAccountPopup = () => {
        return (
            <UserPopupAccount
                key="popup-user-account"
                avatarIconName={userInitials}
                onLoadUser={loadUser}
                onContactSupport={onContactSupport}
                onEditEmail={onOpenEmailEditPopup}
                onEditPassword={onOpenNewPasswordPopup}
                onCloseAndSignOut={() => {
                    setIsVisibleAccountPopup(false);
                    onSignOut();
                }}
                onClose={() => {
                    setIsVisibleAccountPopup(false);
                }}
            />
        );
    };

    const renderPopupConfirmError = () => {
        return (
            <PopupConfirmError
                error={popupConfirmError.state.error}
                onClose={popupConfirmError.close}
            />
        );
    };

    const renderNotificationPopup = () => {
        return (
            <PopupNotification
                id={popupNotification.state.data.id}
                onClose={popupNotification.close}
            />
        );
    };

    const renderRestoreClassesPopup = () => {
        return (
            <PopupRestoreClasses
                onClose={() => {
                    dispatch(actions.uiPopups.clearPopups());
                }}
            />
        );
    };

    const renderPopupClassesLimit = () => {
        return (
            <PopupConfirmClassesLimitExceeded
                totalStudents={store.classesLimitInfo.data?.totalStudents || 0}
                maxStudents={store.classesLimitInfo.data?.maxStudents || 0}
                onClose={popupClassesLimit.close}
            />
        );
    };

    const renderSnackbars = () => {
        return (
            <Snackbar
                bars={snackbar.bars}
                onClose={snackbar.close}
            />
        );
    };

    const renderPopups = () => {
        const popups = [];

        if (store.isTeacherWelcomePopupOpen) {
            popups.push(renderTeacherWelcomePopup());
        }

        if (store.popupBadEmail.isVisible) {
            popups.push(renderPopupBadEmail());
        }

        if (popupConfirmClassesEndDate.state.isVisible) {
            popups.push(renderClassEndDateReminderPopup());
        }

        if (popupMissingClassesEndDate.state.isOpen) {
            popups.push(renderPopupMissingClassesEndDate());
        }

        if (emailPopup.state.isOpen) {
            popups.push(renderEditEmailPopup());
        }

        if (newPasswordPopup.state.isOpen) {
            popups.push(renderNewPasswordPopup());
        }

        if (isVisibleAccountPopup) {
            popups.push(renderAccountPopup());
        }

        if (popupConfirmError.state.isVisible) {
            popups.push(renderPopupConfirmError());
        }

        if (popupNotification.state.isOpen) {
            popups.push(renderNotificationPopup());
        }

        if (store.isVisibleRestoreClasses) {
            popups.push(renderRestoreClassesPopup());
        }

        if (popupClassesLimit.state.isOpen) {
            popups.push(renderPopupClassesLimit());
        }

        return popups;
    };

    const renderContent = () => {
        const supportLink = `${settings.landingSite.domain}${settings.landingSite.routeSupport}`
        const faqLink = `${settings.landingSite.domain}${settings.landingSite.routeFaq}`

        const isDisabledMenuGlobalClose = emailPopup.state.isOpen
            || newPasswordPopup.state.isOpen;

        return (
            <>
                <PopupMessages />

                <UserPopupConfirmExpiration
                    isStudent={roles.isStudent}
                    isTeacher={roles.isTeacher || roles.isParent}
                    onSignOut={onSignOut}
                />

                {renderPopups()}

                {renderSnackbars()}

                <LayoutContainer
                    pathname={loc.pathname}
                    appName={settings.appName}
                    copyright={settings.copyright}
                    version={store.version}
                    sectionsByRoles={settings.sectionsByRoles}
                    notificationProgressReport={Classes.hasNewProgressReport(store.teacher.classes)}
                    notificationsSections={getHeaderBouncingNotifications()}
                    notifications={getNotifications()}
                    classesLimitInfo={getClassesLimitInfo()}
                    achievements={getAchievements()}
                    gradesSettings={getGradesSettings()}
                    user={store.user}
                    avatarIconName={userInitials}
                    supportLink={supportLink}
                    faqLink={faqLink}
                    settings={{ theme: store.theme }}
                    onAutoThemeChange={onAutomaticThemeChange}
                    onThemeChange={onThemeChange}
                    onManageAccount={onManageAccount}
                    onContactSupport={onContactSupport}
                    onEditPassword={onOpenNewPasswordPopup}
                    onEditEmail={onOpenEmailEditPopup}
                    onSaveNewPassword={onSaveNewPassword}
                    onSubscribe={onSubscribe}
                    onSignOut={onSignOut}
                    disabledMenuGlobalClose={isDisabledMenuGlobalClose}
                    isSocketMonitorOnline={store.isSocketMonitorOnline}
                    isVisibleMobileHeader={getIsVisibleMobileHeader()}
                >
                    <ErrorBoundaryWithValue
                        changeValue={loc.pathname}
                        onError={onError}
                    >
                        <Suspense fallback={<RequestLoader />}>
                            <Outlet />
                        </Suspense>
                    </ErrorBoundaryWithValue>
                </LayoutContainer>
            </>
        );
    };

    return (
        <ErrorBoundary onError={onError}>
            <StripeContext.Provider value={stripeCtxRef.current}>
                {renderContent()}
            </StripeContext.Provider>
        </ErrorBoundary>
    );
};

RootLayout.defaultProps = {
    onLoadUser: () => { },
};

export default RootLayout;
