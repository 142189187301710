import array from "@/base/lib/array.js";
import date from "@/base/lib/date.js";


const StoryStates = {
    storyNew: "Check out this fresh new story!",

    quizSkipped: "Ack! You skipped the quiz!",
    quizCorrect: "1 out of 1. You nailed it!",
    quizIncorrect: "0 out of 1. You'll get it next time!",
    quizEmpty: "No quiz today! You're doing great!",

    pollVoted: "You voted!",
    pollOpen: "The polls are open!",

    videoNew: "Hit play and check out this great new video!",
    videoWatched: "You're all caught up on videos. Strong work!",
};

const StoryStatesLegend = {
    notepadExclamation: "Article + Quiz Skipped",

    notepadStop: "Quiz Skipped",
    notepadCheck: "Quiz Complete (Correct)",
    notepadX: "Quiz Complete (Incorrect)",

    playInDottedCircle: "Video Skipped",
    playInCircle: "Video Played",

    pollAnswered: "Poll Answered",
    pollUnanswered: "Poll Skipped",
};

const VIEW_TYPES = {
    scoreReport: "score-report",
    progress: "progress",
    dailyJuices: "daily-juices",
    assignments: "assignments",
};

const DAILY_JUICES_TYPES = [
    { label: "Daily Juices", value: VIEW_TYPES.dailyJuices },
    { label: "Assignments", value: VIEW_TYPES.assignments },
];

const DAILY_JUICES_TYPES_V2 = [
    { label: "Progress", value: VIEW_TYPES.progress },
    { label: "Daily Juices", value: VIEW_TYPES.dailyJuices },
    { label: "Assignments", value: VIEW_TYPES.assignments },
];

const DAILY_JUICES_TYPES_V3 = [
    { label: "Score Report", value: VIEW_TYPES.scoreReport },
    { label: "Progress", value: VIEW_TYPES.progress },
    { label: "Daily Juices", value: VIEW_TYPES.dailyJuices },
    { label: "Assignments", value: VIEW_TYPES.assignments },
];

const DAILY_JUICES_STATUSES = {
    answered: {
        name: "answered",
        label: "All Quizzes Answered",
        color: "var(--dj-status-answered)",
    },
    inProgress: {
        name: "in-progress",
        label: "Some Quizzes Answered",
        color: "var(--dj-status-in-progress)",
    },
    unanswered: {
        name: "unanswered",
        label: "No Quizzes Answered",
        color: "var(--dj-status-unanswered)",
    },
    correct: {
        name: "correct",
        label: "Correct",
        color: "var(--dj-status-correct)",
    },
    incorrect: {
        name: "incorrect",
        label: "Incorrect",
        color: "var(--dj-status-incorrect)",
    },
    pending: {
        name: "pending",
        label: "Pending",
        color: "var(--dj-status-pending)",
    },
};

const Categories = {
    POLLS: "Polls",
};

/* ----- */

const isScoreReportView = (view) => {
    return view === VIEW_TYPES.scoreReport;
};

const isDailyJuiceView = (view) => {
    return view === VIEW_TYPES.dailyJuices;
};

const isAssignmentsView = (view) => {
    return view === VIEW_TYPES.assignments;
};

const isProgressView = (view) => {
    return view === VIEW_TYPES.progress;
};

const isValidDailyJuiceType = (view) => {
    if (!view) {
        return false;
    }

    for (let i = 0; i < DAILY_JUICES_TYPES.length; i += 1) {
        const vw = DAILY_JUICES_TYPES[i].value;

        if (view === vw) {
            return true;
        }
    }

    return false;
};

// TODO: replace isValidDailyJuiceType
const isValidDailyJuiceTypeV2 = (view) => {
    if (!view) {
        return false;
    }

    for (let i = 0; i < DAILY_JUICES_TYPES_V2.length; i += 1) {
        const vw = DAILY_JUICES_TYPES_V2[i].value;

        if (view === vw) {
            return true;
        }
    }

    return false;
};

const isValidDailyJuiceTypeV3 = (view) => {
    if (!view) {
        return false;
    }

    for (let i = 0; i < DAILY_JUICES_TYPES_V3.length; i += 1) {
        const vw = DAILY_JUICES_TYPES_V3[i].value;

        if (view === vw) {
            return true;
        }
    }

    return false;
};

const isCompleteStatus = (status) => {
    return status === DAILY_JUICES_STATUSES.answered.name;
};

const isInProgressStatus = (status) => {
    return status === DAILY_JUICES_STATUSES.inProgress.name;
};

const isUnansweredStatus = (status) => {
    return status === DAILY_JUICES_STATUSES.unanswered.name;
};

/* ----- */

const getStatusByName = (status) => {
    const keys = Object.keys(DAILY_JUICES_STATUSES);

    for (let i = 0; i < keys.length; i += 1) {
        const value = DAILY_JUICES_STATUSES[keys[i]];

        if (status === value.name) {
            return value;
        }
    }

    return null;
};

const getDailyJuiceStatuses = () => {
    return [
        DAILY_JUICES_STATUSES.answered,
        DAILY_JUICES_STATUSES.inProgress,
        DAILY_JUICES_STATUSES.unanswered,
    ];
};

const getAssignmentsStatuses = () => {
    return [
        DAILY_JUICES_STATUSES.correct,
        DAILY_JUICES_STATUSES.incorrect,
        DAILY_JUICES_STATUSES.pending,
    ];
};

const getDailyJuicesTypes = () => {
    return [...DAILY_JUICES_TYPES];
};

// TODO: replace getDailyJuicesTypes
const getDailyJuicesTypesV2 = () => {
    return [...DAILY_JUICES_TYPES_V2];
};

const getDailyJuicesTypesV3 = () => {
    return [...DAILY_JUICES_TYPES_V3];
};

const getDailyJuiceStatusByScores = (totalQuizzes, studentAnsweredQuizzes) => {
    if (totalQuizzes === studentAnsweredQuizzes) {
        return DAILY_JUICES_STATUSES.answered;
    }

    if (studentAnsweredQuizzes === 0) {
        return DAILY_JUICES_STATUSES.unanswered;
    }

    if (totalQuizzes > studentAnsweredQuizzes) {
        return DAILY_JUICES_STATUSES.inProgress;
    }

    return DAILY_JUICES_STATUSES.unanswered;
};

/* ----- */

const getJuiceIdByDate = (d, juicesDates) => {
    if (!d) {
        return null;
    }

    for (let i = 0; i < juicesDates.length; i += 1) {
        const juice = juicesDates[i];

        if (date.isDatesSame(d, juice.date)) {
            return juice.juiceId;
        }
    }

    return null;
};

const getJuiceIdByDateV2 = (d, juicesDates) => {
    if (!d) {
        return null;
    }

    for (let i = 0; i < juicesDates.length; i += 1) {
        const juice = juicesDates[i];

        if (date.isDatesSame(d, juice.date)) {
            return juice.id;
        }
    }

    return null;
};

const getJuiceDateByJuiceId = (juicesById, id) => {
    if (!juicesById || !id) {
        return null;
    }

    const juice = juicesById?.[id]?.data;

    if (juice) {
        return juice?.juiceDate || null;
    }

    return null;
};

/* ----- */

const getStoriesWithoutPolls = (stories) => {
    if (!stories) {
        return [];
    }

    return stories.filter((story) => {
        if (story.polls && story.polls.length > 0) {
            return false;
        }

        if (story.categoryName === Categories.POLLS) {
            return false;
        }

        return true;
    });
};

const getStoryPollWithInfo = (poll = {}, storyInfo = {}) => {
    const pollWithInfo = { ...poll };

    const pollData = storyInfo.poll || {};
    const pollResults = pollData.percentResults || [];

    pollWithInfo.userAnswerId = pollData.userAnswerId || null;
    pollWithInfo.totalVotes = pollData.totalVotes || 0;

    const pollAnswers = pollWithInfo.answers || [];

    for (let i = 0; i < pollAnswers.length; i += 1) {
        const pollAnswer = pollAnswers[i];

        for (let j = 0; j < pollResults.length; j += 1) {
            const pollResult = pollResults[j] || {};

            if (pollResult.answerId === pollAnswer.id) {
                pollAnswers[i].percent = pollResult.percent;
                break;
            }
        }
    }

    pollWithInfo.answers = pollAnswers;

    return pollWithInfo;
};

const getStoriesWithInfoV2 = (stories = [], info = {}) => {
    return stories.map((story) => {
        const s = { ...story };

        const sId = s.ID || null;

        const storyInfo = info.stories?.[sId] || null;

        if (storyInfo) {
            s.isUserOpenedStory = !!storyInfo.isOpenedStory;
            s.rate = storyInfo.storyRating || 0;

            if (s.featuredVideo) {
                s.featuredVideo.isUserViewed = !!info.isVideoViewed;
            }

            if (s.polls?.[0]) {
                s.polls[0].userAnswerId = info.pollAnswerId || null;
            }

            if (s.extraJuices?.[0]) {
                s.extraJuices[0].rate = storyInfo.extraJuiceRating || 0;
            }

            const quizAnswers = s?.quizzes?.[0]?.answers || [];

            if (storyInfo.quizAnswerId) {
                for (let i = 0; i < quizAnswers.length; i += 1) {
                    const answer = quizAnswers[i] || {};

                    if (answer.answerId === storyInfo.quizAnswerId) {
                        s.quizzes[0].answers[i].is_user_choice = true;
                        break;
                    }
                }
            }
        }

        return s;
    }).filter((s) => !s.isTeacherOnlyCategory);
};

const getStoriesWithInfo = (stories = [], storiesInfo = {}) => {
    return stories.map((story) => {
        const s = { ...story };

        const sId = s.ID || null;

        const storyInfo = storiesInfo[sId] || null;

        if (storyInfo) {
            s.isUserOpenedStory = !!storyInfo.isOpenedStory;
            s.rate = storyInfo.storyRating || 0;

            if (s.featuredVideo) {
                s.featuredVideo.isUserViewed = !!storyInfo.isVideoViewed;
            }

            if (s.polls?.[0]) {
                s.polls[0] = getStoryPollWithInfo(s.polls[0], storyInfo);
            }

            if (s.extraJuices?.[0]) {
                s.extraJuices[0].rate = storyInfo.extraJuiceRating || 0;
            }

            const quizAnswers = s?.quizzes?.[0]?.answers || [];

            if (storyInfo.quizAnswerId) {
                for (let i = 0; i < quizAnswers.length; i += 1) {
                    const answer = quizAnswers[i] || {};

                    if (answer.answerId === storyInfo.quizAnswerId) {
                        s.quizzes[0].answers[i].is_user_choice = true;
                        break;
                    }
                }
            }
        }

        return s;
    }).filter((s) => !s.isTeacherOnlyCategory);
};

const getStudentStoriesWithoutPolls = (stories) => {
    const storiesWithoutPolls = getStoriesWithoutPolls(stories);

    return getStoriesWithInfo(storiesWithoutPolls);
};

const getStoryById = (stories, storyId) => {
    for (let i = 0; i < stories.length; i += 1) {
        const s = stories[i];

        if (s.ID === storyId) {
            return s;
        }
    }

    return null;
};

const getNextStory = (stories, storyId) => {
    for (let i = 0; i < stories.length; i += 1) {
        const s = stories[i];

        if (s.ID === storyId) {
            if (stories[i + 1]) {
                return stories[i + 1];
            }

            return null;
        }
    }

    return null;
};

const getPrevStory = (stories, storyId) => {
    for (let i = 0; i < stories.length; i += 1) {
        const s = stories[i];

        if (s.ID === storyId) {
            if (stories[i - 1]) {
                return stories[i - 1];
            }

            return null;
        }
    }

    return null;
};

const getExtraJuiceById = (stories, extraJuiceId) => {
    for (let i = 0; i < stories.length; i += 1) {
        const ejs = stories[i].extraJuices || [];

        for (let j = 0; j < ejs.length; j += 1) {
            const ej = ejs[j];

            if (ej.id === extraJuiceId) {
                return ej;
            }
        }
    }

    return null;
};

/* --- */

const getStoryQuiz = (story) => {
    const quizzes = story.quizzes || [];

    if (quizzes.length === 0) {
        return null;
    }

    return quizzes[0] || null;
};

const getQuizById = (quizzes, quizId) => {
    return array.findByFieldName(quizzes, "quizId", quizId);
};

const isValidQuiz = (quiz) => {
    const anss = quiz.answers || [];

    for (let i = 0; i < anss.length; i += 1) {
        const ans = anss[i];

        if (ans.is_correct_answer !== ""
            && ans.is_correct_answer === true
            && ans.is_user_choice === true) {
            return true;
        }
    }

    return false;
};

const isAnsweredQuiz = (quiz) => {
    const quizAnswers = quiz?.answers || [];

    for (let i = 0; i < quizAnswers.length; i += 1) {
        const answer = quizAnswers[i] || {};

        if (answer.is_user_choice) {
            return true;
        }
    }

    return false;
};

const getQuestionAnswer = (question) => {
    const ans = question.answers || [];

    for (let i = 0; i < ans.length; i += 1) {
        if (ans[i].is_user_choice) {
            return ans[i];
        }
    }

    return false;
};

const fixExFeedback = (feedback, isCorrect = false) => {
    if (!feedback) {
        return [];
    }

    return feedback.map((message, index) => {
        if (index === 0) {
            const messageStart = isCorrect
                ? "Nice job!"
                : "Good try! You're working hard to understand this.";

            return `${messageStart} ${message}`;
        }

        return message;
    }).filter(array.filterCallbackNonEmptyString);
};

const getQuizExplicitFeedback = (quiz) => {
    const isQuizCorrect = isValidQuiz(quiz);

    let feedback = quiz?.explicitFeedback || [];

    if (quiz?.explicitFeedbackCustom?.length) {
        feedback = quiz.explicitFeedbackCustom;
    }

    return fixExFeedback(feedback, isQuizCorrect);
};

/* --- */

const getQuizzesStats = (stories) => {
    const stats = {
        quizIds: [],

        firstSkipped: null,

        total: 0,
        answered: 0,
        correct: 0,
    };

    stories.forEach((s) => {
        const quizzes = s.quizzes || [];

        if (quizzes.length > 0) {
            const q = quizzes[0];
            const answer = getQuestionAnswer(q);

            stats.quizIds.push(q.quizId);

            if (answer) {
                stats.answered += 1;

                if (answer.is_correct_answer
                    && answer.is_user_choice) {
                    stats.correct += 1;
                }
            } else if (!stats.firstSkipped) {
                stats.firstSkipped = s.ID;
            }

            stats.total += 1;
        }
    });

    return stats;
};

/* --- */

const getQuizStandards = (quiz) => {
    const standards = [];

    if (quiz?.standards) {
        return quiz.standards;
    }

    return standards;
};

/* --- */


export default {
    StoryStates,
    StoryStatesLegend,

    isScoreReportView,
    isDailyJuiceView,
    isAssignmentsView,
    isProgressView,
    isValidDailyJuiceType,
    isValidDailyJuiceTypeV2,
    isValidDailyJuiceTypeV3,

    isCompleteStatus,
    isInProgressStatus,
    isUnansweredStatus,
    getStatusByName,
    getDailyJuiceStatuses,
    getAssignmentsStatuses,
    getDailyJuicesTypes,
    getDailyJuicesTypesV2,
    getDailyJuicesTypesV3,
    getDailyJuiceStatusByScores,

    getJuiceIdByDate,
    getJuiceIdByDateV2,
    getJuiceDateByJuiceId,

    getStoriesWithoutPolls,
    getStoriesWithInfo,
    getStoriesWithInfoV2,
    getStudentStoriesWithoutPolls,

    getStoryById,
    getNextStory,
    getPrevStory,
    getExtraJuiceById,

    getStoryQuiz,
    getQuizById,
    isValidQuiz,
    isAnsweredQuiz,
    getQuestionAnswer,
    getQuizzesStats,
    fixExFeedback,
    getQuizExplicitFeedback,

    getQuizStandards,
};
