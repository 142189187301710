import Classes from "@/base/project/classes.js";
import Schools from "@/base/project/schools.js";

import text from "@/base/text/index.js";


const loadClassTeachers = (services, params) => {
    const { api, actions } = services;
    const { classId } = params;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.teacher.setTeacherClassesTeachersByIdLoading({
            classId,
        }));

        const res = await api.classes.getClassTeachers({
            session: state.user.session,
            classId,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.data || null;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.teacher.setTeacherClassesTeachersById({
            classId,
            data,
            error,
        }));
    };
};

const reloadClassTeachers = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const classId = state.teacher.selectedClassId;

        if (!classId || classId === "-1") {
            return;
        }

        dispatch(actions.teacher.setTeacherClassesTeachersByIdLoading({
            classId,
        }));

        const res = await api.classes.getClassTeachers({
            session: state.user.session,
            classId,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.data || null;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.teacher.setTeacherClassesTeachersById({
            classId,
            data,
            error,
        }));
    };
};

const deleteTeacherFromClass = (services, params) => {
    const { api } = services;
    const { teacherId, classId } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.classes.deleteTeacherFromClass({
            session: state.user.session,
            teacherId,
            classId,
        });

        let error = "";

        if (!res.ok) {
            error = res.error || text.error;
        }

        return {
            error,
        };
    };
};

const deleteClass = (services, params) => {
    const { api } = services;
    const { classId } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.classes.deleteClass({
            session: state.user.session,
            classId,
        });

        let error = "";

        if (!res.ok) {
            error = res.error || text.error;
        }

        return {
            error,
        };
    };
};

const addStudentToClass = (services, params) => {
    const { api } = services;
    const {
        classId,
        firstname,
        lastname,
        email,
        grade,
        addNewOnDuplicate,
    } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.classes.addStudent({
            session: state.user.session,
            classId,
            firstname,
            lastname,
            email,
            grade,
            addNewOnDuplicate,
        });

        let error = "";
        let student = {};

        let isFullNameDuplicate = false;
        let isEmailDuplicate = false;
        let isInAnotherClass = false;
        let isAlreadyInThisClass = false;

        if (!res.ok) {
            const details = res.details || {};

            student = details?.student || {};

            isFullNameDuplicate = details.isFullNameDuplicate || false;
            isEmailDuplicate = details.isEmailDuplicate || false;
            isInAnotherClass = details.isInAnotherClass || false;
            isAlreadyInThisClass = details.isAlreadyInThisClass || false;

            error = res.error || text.error;
        }

        return {
            error,
            student,
            isFullNameDuplicate,
            isEmailDuplicate,
            isInAnotherClass,
            isAlreadyInThisClass,
        };
    };
};

const setupClass = (services, params) => {
    const { api } = services;
    const {
        selectedSchoolType,
        selectedDistrictId,
        selectedCity,
        country,
        subdivision,
        district,
        city,
        schoolName,
        department,
        className,
        grades,
        endDate,
    } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const schools = Schools.isPublicSchoolType(selectedSchoolType)
            ? state.geo.schoolsByDistrictId?.[selectedDistrictId] || []
            : state.geo.schoolsByCity?.[selectedCity] || [];

        const res = await api.classes.setupTeacherClass({
            session: state.user.session,
            schoolType: selectedSchoolType,
            country,
            subdivision,
            district,
            city,
            schoolName,
            schoolId: Schools.getSchoolIdByName(schools, schoolName),
            department,
            className,
            grades,
            endDate,
        });

        let error = "";

        if (!res.ok) {
            error = res.error || text.error;
        }

        return {
            error,
        };
    };
};

const setStudentProgressNotificationViewed = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        if (!state.user.session) {
            return;
        }

        const classId = state.teacher.selectedClassId || "";
        const classes = state.teacher.classes || [];

        const selectedClass = Classes.getClassById(classes, classId);

        if (!selectedClass || !selectedClass.hasNewProgressReport) {
            return;
        }

        const res = await api.teacherClass.setProgressReportViewed({
            session: state.user.session,
            classId: selectedClass.id,
        });

        if (res.ok) {
            dispatch(actions.teacher.setClassProgressReport({
                classId: selectedClass.id,
                hasNewProgressReport: false,
            }));
        }
    };
};

const removeStudentFromClass = (services, params) => {
    const { api, events } = services;
    const { studentId, classId } = params;

    return async (dispatch, getState) => {
        const state = getState();
        const { session } = state.user;

        const apiParams = {
            session,
            classId,
            studentId,
        };

        events.classes.deleteStudent(apiParams);

        const res = await api.classes.removeStudentById(apiParams);

        let error = "";

        if (!res.ok) {
            error = res.error || text.error;
        }

        return {
            error,
        };
    };
};

const loadClassNotifications = (services, params) => {
    const { actions, api } = services;
    const { classId } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const session = state.user.session;

        const res = await api.classes.getClassNotifications({
            session,
            classId,
        });

        let notifications = [];

        if (res.ok) {
            notifications = res.students || [];
        }

        dispatch(actions.notifications.setStudentsNotifications({
            classId,
            notifications,
        }));
    };
};

const loadClassStudentsGradesByRange = (services, params) => {
    const { api, actions } = services;
    const { classId, dateFrom, dateTo } = params;

    return async (dispatch, getState) => {
        const state = getState();
        const { session } = state.user;

        dispatch(actions.teacherClass.setClassStudentsGradesByRangeLoading({
            classId,
            dateFrom,
            dateTo,
        }));

        const res = await api.teacherClasses.getClassStudentsGradesByRange({
            session,
            classId,
            dateFrom,
            dateTo,
        });

        let grades = [];
        let error = "";

        if (res.ok) {
            grades = res.grades || [];
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.teacherClass.setClassStudentsGradesByRange({
            classId,
            grades,
            error,
            dateFrom,
            dateTo,
        }));

        return {
            grades,
            error,
        };
    };
};

const reloadClassStudentsGradesByRange = (services, params) => {
    const { api, actions } = services;
    const { classId, dateFrom, dateTo } = params;

    return async (dispatch, getState) => {
        const state = getState();
        const { session } = state.user;

        const res = await api.teacherClasses.getClassStudentsGradesByRange({
            session,
            classId,
            dateFrom,
            dateTo,
        });

        let grades = [];
        let error = "";

        if (res.ok) {
            grades = res.grades || [];
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.teacherClass.setClassStudentsGradesByRange({
            classId,
            grades,
            error,
            dateFrom,
            dateTo,
        }));

        return {
            grades,
            error,
        };
    };
};

const loadOrReloadClassStudentsGradesByRange = (services, params) => {
    const { classId, dateFrom, dateTo } = params;

    return async (dispatch, getState) => {
        const state = getState();
        const { classStudentsGradesByRange } = state.teacherClass;
        const range = `${dateFrom}-${dateTo}`;

        const data = classStudentsGradesByRange[classId]?.[range] || {};

        if (!data.isLoaded || data.error) {
            return dispatch(loadClassStudentsGradesByRange(services, params));
        }

        return dispatch(reloadClassStudentsGradesByRange(services, params));
    };
};

export default {
    loadClassTeachers,
    reloadClassTeachers,
    deleteTeacherFromClass,
    addStudentToClass,
    deleteClass,
    setupClass,
    setStudentProgressNotificationViewed,
    removeStudentFromClass,
    loadClassNotifications,

    loadOrReloadClassStudentsGradesByRange,
};
