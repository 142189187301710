import text from "@/base/text/index.js";


const handleTeacherClassesResponse = (res = {}) => {
    let classes = [];
    let maxClasses = null;
    let hasPrimaryClasses = false;
    let error = "";

    if (res.ok) {
        classes = res.classes || [];
        maxClasses = res.maxClasses || null;
        hasPrimaryClasses = !!res.hasPrimaryClasses;
    } else {
        error = res.error || text.error;
    }

    return {
        classes,
        maxClasses,
        hasPrimaryClasses,
        error,
    };
};

const loadAllClasses = (services) => {
    const { actions, api } = services;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.teacher.setClassesLoading());

        const res = await api.classes.getTeacherClasses({
            session: state.user.session,
        });

        const data = handleTeacherClassesResponse(res);

        dispatch(actions.teacher.setClasses({
            classes: data.classes,
            maxClasses: data.maxClasses,
            hasPrimaryClasses: data.hasPrimaryClasses,
        }));

        return data;
    };
};

const reloadAllClasses = (services) => {
    const { actions, api } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.classes.getTeacherClasses({
            session: state.user.session,
        });

        const data = handleTeacherClassesResponse(res);

        dispatch(actions.teacher.setClasses({
            classes: data.classes,
            maxClasses: data.maxClasses,
            hasPrimaryClasses: data.hasPrimaryClasses,
        }));

        return data;
    };
};

/* --- */

const loadClassesStudents = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        dispatch(actions.teacher.setTeacherClassesStudentsLoading());

        const state = getState();

        const res = await api.classes.getTeacherClassesStudents({
            session: state.user.session,
        });

        let classes = null;
        let error = "";

        if (res.ok) {
            classes = res.data || [];
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.teacher.setTeacherClassesStudents({
            error,
            classes,
        }));

        return {
            classes,
            error,
        };
    };
};

/* --- */

const loadDerosteredClasses = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.teacher.setTeacherDerosteredClassesLoading());

        const res = await api.teacherClasses.getDerosteredClasses({
            session: state.user.session,
        });

        let data = [];
        let error = "";

        if (res.ok) {
            data = res.classes;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.teacher.setTeacherDerosteredClasses({
            data,
            error,
        }));
    };
};

const restoreClass = (services, params) => {
    const { api } = services;
    const { derosteringId } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.teacherClasses.restoreClass({
            session: state.user.session,
            derosteringId,
        });

        let error = "";

        if (!res.ok) {
            error = res.error || text.error;
        }

        return {
            error,
        };
    };
};


export default {
    loadAllClasses,
    reloadAllClasses,
    loadClassesStudents,
    loadDerosteredClasses,
    restoreClass,
};
