import * as globalTypes from "@/base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    assignmentsByGroupCode: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_ASSIGNMENTS_BY_GROUP_CODE_LOADING: {
            const { groupCode } = action.payload;
            const prevDataByCode = state.assignmentsByGroupCode[groupCode] || {};

            return {
                ...state,
                assignmentsByGroupCode: {
                    ...state.assignmentsByGroupCode,
                    [groupCode]: {
                        ...prevDataByCode,
                        isLoading: true,
                    },
                },
            };
        }

        case types.SET_ASSIGNMENTS_BY_GROUP_CODE: {
            const { groupCode, data, error } = action.payload;

            return {
                ...state,
                assignmentsByGroupCode: {
                    ...state.assignmentsByGroupCode,
                    [groupCode]: {
                        data,
                        error,
                        isLoading: false,
                        isLoaded: true,
                    },
                },
            };
        }

        case types.SET_ASSIGNMENTS_BY_GROUP_CODE_STORY_VIEWED: {
            const { groupCode, storyId } = action.payload;

            const data = state.assignmentsByGroupCode?.[groupCode]?.data;
            const stories = data?.stories || [];

            const newStories = [];

            for (let i = 0; i < stories.length; i += 1) {
                const story = stories[i];

                if (story.ID === storyId) {
                    story.isUserOpenedStory = true;
                }

                newStories.push(story);
            }

            return {
                ...state,
                assignmentsByGroupCode: {
                    ...state.assignmentsByGroupCode,
                    [groupCode]: {
                        ...state.assignmentsByGroupCode[groupCode],
                        data: {
                            ...data,
                            stories: newStories,
                        },
                    },
                },
            };
        }

        case types.SET_ASSIGNMENTS_BY_GROUP_CODE_STORY_RATE: {
            const { groupCode, storyId, rating } = action.payload;

            const data = state.assignmentsByGroupCode?.[groupCode]?.data;
            const stories = data?.stories || [];

            const newStories = [];

            for (let i = 0; i < stories.length; i += 1) {
                const story = stories[i];

                if (story.ID === storyId) {
                    story.rate = rating;
                }

                newStories.push(story);
            }

            return {
                ...state,
                assignmentsByGroupCode: {
                    ...state.assignmentsByGroupCode,
                    [groupCode]: {
                        ...state.assignmentsByGroupCode[groupCode],
                        data: {
                            ...data,
                            stories: newStories,
                        },
                    },
                },
            };
        }

        case types.SET_ASSIGNMENTS_BY_GROUP_CODE_STORY_EXTRA_JUICE_RATE: {
            const {
                groupCode,
                storyId,
                extraJuiceId,
                rating,
            } = action.payload;

            const data = state.assignmentsByGroupCode?.[groupCode]?.data;
            const stories = data?.stories || [];

            const newStories = [];

            for (let i = 0; i < stories.length; i += 1) {
                const story = stories[i];

                if (story.ID === storyId) {
                    const extraJuices = story.extraJuices || [];

                    for (let j = 0; j < extraJuices.length; j += 1) {
                        const ej = extraJuices[j];

                        if (ej.id === extraJuiceId) {
                            story.extraJuices[j].rate = rating;
                        }
                    }
                }

                newStories.push(story);
            }

            return {
                ...state,
                assignmentsByGroupCode: {
                    ...state.assignmentsByGroupCode,
                    [groupCode]: {
                        ...state.assignmentsByGroupCode[groupCode],
                        data: {
                            ...data,
                            stories: newStories,
                        },
                    },
                },
            };
        }

        case types.SET_ASSIGNMENTS_BY_GROUP_CODE_STORY_QUIZ_ANSWER: {
            const { groupCode, quizId, questionId, answerId } = action.payload;
            const { assignmentsByGroupCode } = state;

            const assignment = assignmentsByGroupCode[groupCode] || {};

            if (!assignment.data?.stories) {
                return state;
            }

            assignment.data.stories = assignment.data.stories.map((s) => {
                const quizzes = (s.quizzes || []).map((quiz) => {
                    const quizAnswers = [...(quiz.answers || [])].map((answer) => {
                        if (answer.answerId === answerId) {
                            return {
                                ...answer,
                                is_user_choice: true,
                            };
                        }

                        return answer;
                    });

                    return {
                        ...quiz,
                        answers: quizAnswers,
                    };
                });

                return {
                    ...s,
                    quizzes,
                };
            });

            return {
                ...state,
                assignmentsByGroupCode: {
                    ...assignmentsByGroupCode,
                    [groupCode]: assignment,
                },
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
