import React from "react";

import classNames from "@/base/lib/class-names.js";

import LoaderSmall from "@/base/components/loader-small/index.js";
import Text from "@/base/components/text/index.js";
import DivButton from "@/base/components/div-button/index.js";

import styles from "./answer.module.css";


const PollAnswer = (props) => {
    const {
        isDisabled,
        isLoading,
        isSelected,
        isVisibleResults,
    } = props;

    const onSelect = () => {
        if (isDisabled) {
            return;
        }

        props.onSelect();
    };

    const renderBar = () => {
        const barClassName = classNames({
            [styles.bar]: true,
            [styles.barSelected]: isSelected,
        });

        const barStyle = {};

        if (isVisibleResults && !isLoading) {
            barStyle.width = `${props.percent}%`;
        }

        return (
            <div className={styles.barContainer}>
                <div
                    className={barClassName}
                    style={barStyle}
                />
            </div>
        );
    };

    const renderPercent = () => {
        if (!isVisibleResults || isLoading) {
            return null;
        }

        const percent = Number(props.percent).toFixed(0);

        return (
            <div className={styles.percent}>
                {`${percent}%`}
            </div>
        );
    };

    const renderAnswer = () => {
        if (isLoading) {
            return (
                <LoaderSmall />
            );
        }

        return (
            <Text className={styles.answerText}>
                {props.answer}
            </Text>
        );
    };

    /* --- */

    const pollClassName = classNames({
        [styles.poll]: true,
        [styles.pollSelected]: isSelected,
        [styles.pollDisabled]: isDisabled,
    });

    return (
        <DivButton
            className={pollClassName}
            onClick={onSelect}
        >
            {renderBar()}
            <div className={styles.pollContent}>
                <div className={styles.answer}>
                    {renderAnswer()}
                </div>
                <div>
                    {renderPercent()}
                </div>
            </div>
        </DivButton>
    );
};

PollAnswer.defaultProps = {
    isOutdated: false,
    isDisabled: false,
    isLoading: false,
    isSelected: false,
    isVisibleResults: false,
    percent: 0,
    answer: "",
    onSelect: () => { },
};

export default PollAnswer;
