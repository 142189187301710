import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import User from "@/base/project/user.js";

import useTitle from "@/base/hooks/use-title/index.js";

import { withAuth } from "@/base/components/auth/index.js";

import StudentIndexTrial from "@/app/containers/student-index-trial/index.js";
import StudentIndexRegularV2 from "@/app/containers/student-index-regular-v2/index.js";


const Index = () => {
    const store = useSelector((state) => ({
        user: state.user.user,
    }));

    const navigate = useNavigate();

    useTitle(() => "Home", []);

    if (User.hasRoleDistrictAdmin(store.user)
        || User.hasRoleTeacher(store.user)
        || User.hasRoleParent(store.user)
        || User.hasRoleAdmin(store.user)) {
        navigate("/dashboard");
        return null;
    }

    if (User.hasRoleStudent(store.user)
        || User.hasRoleGuardian(store.user)
        || User.hasRoleChild(store.user)) {
        if (User.isTypeTrial(store.user)) {
            return (
                <StudentIndexTrial />
            );
        }

        return (
            <StudentIndexRegularV2 />
        );
    }

    return null;
};

export default withAuth([
    "teacher",
    "parent",
    "student",
    "child",
    "guardian",
    "district_admin",
    "administrator",
])(Index);
