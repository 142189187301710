import React from "react";

import classNames from "@/base/lib/class-names.js";

import TooltipInfo from "@/base/components/tooltip-info/index.js";
import TooltipPosition from "@/base/components/tooltip-position/index.js";
import TooltipToggler from "@/base/components/tooltip-toggler/index.js";
import DivButton from "@/base/components/div-button/index.js";

import styles from "./styles.module.css";


const ButtonCircle = (props) => {
    const onClick = (evt) => {
        if (props.disabled) {
            return;
        }

        props.onClick(evt);
    };

    /* --- */

    const renderTooltip = () => {
        if (!props.tooltip || props.isMobile) {
            return null;
        }

        const positionX = props.tooltip.positionX || "";
        const positionY = props.tooltip.positionY || "";

        return (
            <TooltipPosition
                positionX={positionX}
                positionY={positionY}
            >
                <TooltipInfo
                    isArrowDown={positionY === "top"}
                    isRight={positionX === "right"}
                    isLeft={positionX === "left"}
                >
                    {props.tooltip.message || ""}
                </TooltipInfo>
            </TooltipPosition>
        );
    };

    /* --- */

    const buttonClassName = classNames({
        [styles.button]: true,
        [styles.buttonWithOutline]: !props.isOutlineHidden,
        [styles.buttonDisabled]: props.disabled,
        [styles.imageBackwards]: props.isImageBackwards,
    });

    return (
        <TooltipToggler
            tooltip={renderTooltip()}
        >
            <DivButton
                className={buttonClassName}
                dataComment={props.dataComment}
                onClick={onClick}
            >
                {props.icon}
            </DivButton>
        </TooltipToggler>
    );
};

ButtonCircle.defaultProps = {
    icon: null,
    tooltip: null,
    dataComment: "",
    isMobile: false,
    disabled: false,
    isImageBackwards: false,
    isOutlineHidden: false,
    onClick: () => { },
};

export default ButtonCircle;
