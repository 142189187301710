export const SET_JUICES_LOADING = "SET_JUICES_LOADING";

export const SET_JUICES = "SET_JUICES";
export const CLEAR_JUICES = "CLEAR_JUICES";

export const SET_JUICE_LOADING = "SET_JUICE_LOADING";
export const SET_JUICE = "SET_JUICE";
export const CLEAR_JUICE = "CLEAR_JUICE";

export const SET_JUICE_QUESTION = "SET_JUICE_QUESTION";
export const SET_JUICE_QUESTION_ANSWER = "SET_JUICE_QUESTION_ANSWER";

export const SET_LAST_VISITED_JUICE = "SET_LAST_VISITED_JUICE";

export const SET_JUICES_DATES = "SET_JUICES_DATES";
export const SET_JUICES_DATES_LOADED = "SET_JUICES_DATES_LOADED";
export const CLEAR_JUICES_DATES = "CLEAR_JUICES_DATES";

export const SET_JUICES_DATES_BY_MONTH_BY_GRADES_LOADING = "SET_JUICES_DATES_BY_MONTH_BY_GRADES_LOADING";
export const SET_JUICES_DATES_BY_MONTH_BY_GRADES = "SET_JUICES_DATES_BY_MONTH_BY_GRADES";

export const SET_JUICES_DATES_BY_GRADES_LOADING = "SET_JUICES_DATES_BY_GRADES_LOADING";
export const SET_JUICES_DATES_BY_GRADES = "SET_JUICES_DATES_BY_GRADES";

export const SET_JUICES_DATES_BY_MONTH_BY_STUDENT_ID_LOADING = "SET_JUICES_DATES_BY_MONTH_BY_STUDENT_ID_LOADING";
export const SET_JUICES_DATES_BY_MONTH_BY_STUDENT_ID = "SET_JUICES_DATES_BY_MONTH_BY_STUDENT_ID";

export const SET_JUICES_DATES_BY_MONTH_LOADING = "SET_JUICES_DATES_BY_MONTH_LOADING";
export const SET_JUICES_DATES_BY_MONTH = "SET_JUICES_DATES_BY_MONTH";

export const SET_STUDENT_JUICES_BY_ID = "SET_STUDENT_JUICES_BY_ID";
export const SET_STUDENT_JUICES_BY_ID_LOADING = "SET_STUDENT_JUICES_BY_ID_LOADING";
export const CLEAR_STUDENT_JUICES_BY = "CLEAR_STUDENT_JUICES_BY";

export const SET_JUICE_POLL_LOADING = "SET_JUICE_POLL_LOADING";
export const SET_JUICE_POLL_ANSWERED = "SET_JUICE_POLL_ANSWERED";
export const SET_JUICE_POLL_LOADED = "SET_JUICE_POLL_LOADED";
export const SET_JUICE_POLL_ERROR = "SET_JUICE_POLL_ERROR";
