import React, { useState } from "react";

import classNames from "@/base/lib/class-names.js";

import IconQuestion from "@/base/icons/question/index.js";

import TooltipInfo from "@/base/components/tooltip-info/index.js";
import TooltipPosition from "@/base/components/tooltip-position/index.js";
import TooltipToggler from "@/base/components/tooltip-toggler/index.js";
import DivButton from "@/base/components/div-button/index.js";
import IconJumping from "@/base/components/icon-jumping/index.js";

import styles from "./styles.module.css";


const TooltipInfoWithIcon = (props) => {
    const [isTooltipVisible, setTooltipVisible] = useState(false);

    /* --- */

    const onToggleTooltip = (evt) => {
        evt.stopPropagation();
    };

    const onHover = (isHovering) => {
        setTooltipVisible(isHovering);
        props.onHover(isHovering);
    };

    /* --- */

    const renderTooltip = () => {
        if (!props.tooltip) {
            return null;
        }

        const positionX = props.tooltip.positionX || "";
        const positionY = props.tooltip.positionY || "";

        return (
            <TooltipPosition
                positionX={positionX}
                positionY={positionY}
            >
                <TooltipInfo
                    isRight={positionX === "right"}
                    isLeft={positionX === "left"}
                    isArrowDown={positionY === "top"}
                    isTextCentered
                >
                    {props.tooltip.message || ""}
                </TooltipInfo>
            </TooltipPosition>
        );
    };

    const renderIcon = () => {
        const Icon = props.icon
            ? props.icon
            : IconQuestion;

        const iconColor = {};

        if (props.isSky) {
            iconColor.isSky = true;
        } else if (props.isRed) {
            iconColor.isRed = true;
        } else {
            iconColor.isGrey = true;
        }

        return (
            <Icon
                className={styles.icon}
                { ...iconColor }
            />
        );
    };

    const renderIconContent = () => {
        const containerClassName = classNames({
            [styles.container]: true,
            [styles.containerSmall]: props.isMinSize,
            [styles.containerSkySelected]: props.isSky && isTooltipVisible,
        });

        const icon = renderIcon();

        let iconContent = null;

        if (props.isIconJumping) {
            iconContent = (
                <IconJumping
                    className={styles.iconJumpingContainer}
                >
                    {icon}
                </IconJumping>
            );
        } else {
            iconContent = icon;
        }

        return (
            <div className={containerClassName}>
                {iconContent}
            </div>
        );
    };

    /* --- */

    return (
        <DivButton
            onClick={onToggleTooltip}
        >
            <TooltipToggler
                tooltip={renderTooltip()}
                onHover={onHover}
            >
                {renderIconContent()}
            </TooltipToggler>
        </DivButton>
    );
};

TooltipInfoWithIcon.defaultProps = {
    tooltip: null,
    icon: null,
    onHover: () => { },
    isMinSize: false,
    isIconJumping: false,
    isSky: false,
    isRed: false,
};

export default TooltipInfoWithIcon;
