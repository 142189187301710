import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import scroll from "@/base/lib/scroll.js";

import Notifications from "@/base/project/notifications.js";
import User from "@/base/project/user.js";

import useBodyScroll from "@/base/hooks/use-body-scroll/index.js";

import Header from "@/base/components/header/index.js";
import AsideNav from "@/base/components/aside-nav/index.js";
import ShortNav from "@/base/components/short-nav/index.js";
import PopupThemeSettings from "@/base/components/popup-theme-settings/index.js";
import NotificationsNav, { useNotificationsNav } from "@/base/components/notifications-nav/index.js";

import settings from "@/app/settings.js";
import app from "@/app/app.js";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    dimensions: state.device.dimensions,
});

const LayoutContainer = (props) => {
    const navigate = useNavigate();

    const [isVisibleAsideNav, setVisibleAsideNav] = useState(false);
    const [isVisibleThemeSettings, setIsVisibleThemeSettings] = useState(false);

    const store = useSelector(storeSelector);

    const isTeacher = User.hasRoleTeacher(props.user);
    const isDistrictAdmin = User.hasRoleDistrictAdmin(props.user);
    const isParent = User.hasRoleParent(props.user);
    const isAdmin = User.hasRoleAdmin(props.user);

    const notificationsNav = useNotificationsNav();

    const bodyScroll = useBodyScroll(store.dimensions);

    /* ------ */

    const isStory = () => {
        return /^\/daily-juices\/[0-9]+\/[0-9]/.test(props.pathname)
            || /^\/daily-juices\/[0-9]+\/activity/.test(props.pathname)
            || /^\/assignments\/[A-Za-z0-9]+/.test(props.pathname);
    };

    const isVisibleHomeLink = () => {
        if (isTeacher || isDistrictAdmin || isParent || isAdmin) {
            return false;
        }

        return true;
    };

    /* ------ */

    const onShowAsideNav = () => {
        scroll.hideBodyScroll();
        setVisibleAsideNav(true);
    };

    const onHideAsideNav = () => {
        scroll.unhideBodyScroll();
        setVisibleAsideNav(false);
    };

    const onGoToTelemetry = () => {
        navigate("/telemetry");
    };

    /* ------ */

    const renderHeader = () => {
        if (!props.isVisibleMobileHeader) {
            return null;
        }

        const isSubscribeVisible = (isTeacher || isParent)
            && User.isTypeFree(props.user)
            && props.pathname.indexOf("/subscribe") === -1;

        const isEnabledReadingMode = props.user.isEnabledReadingMode || false;

        const isBorderVisible = !isStory();

        const notifications = {
            onToggle: (evt) => {
                evt.stopPropagation();
                notificationsNav.toggle();
            },
            isBadgeVisible: Notifications.hasUnreadNotifications(props.notifications.data),
            isActive: notificationsNav.state.isVisible,
        };

        return (
            <Header
                name={props.appName}
                user={props.user}
                avatarIconName={props.avatarIconName}
                pathname={props.pathname}
                sectionsByRoles={props.sectionsByRoles}
                notificationProgressReport={props.notificationProgressReport}
                notificationsSections={props.notificationsSections}
                notifications={notifications}
                gradesSettings={props.gradesSettings}
                classesLimitInfo={props.classesLimitInfo}
                achievements={props.achievements}
                theme={props.settings.theme?.theme || ""}
                bodyScroll={bodyScroll}
                onAsideToggle={onShowAsideNav}
                onManageAccount={props.onManageAccount}
                onContactSupport={props.onContactSupport}
                onEditPassword={props.onEditPassword}
                onEditEmail={props.onEditEmail}
                onSubscribe={props.onSubscribe}
                onSignOut={props.onSignOut}
                onSettings={() => {
                    setIsVisibleThemeSettings(true);
                }}
                onChangeReadingMode={app.actions.common.user.setReadingMode}
                isVisibleHomeLink={isVisibleHomeLink()}
                isSubscribeVisible={isSubscribeVisible}
                isBorderVisible={isBorderVisible}
                isVisibleReadingMode
                isEnabledReadingMode={isEnabledReadingMode}
                disabledMenuGlobalClose={props.disabledMenuGlobalClose}
            />
        );
    };

    const renderAsideNav = () => {
        if (!isVisibleAsideNav) {
            return null;
        }

        return (
            <AsideNav
                name={props.appName}
                version={props.version}
                user={props.user}
                pathname={props.pathname}
                sectionsByRoles={props.sectionsByRoles}
                supportLink={props.supportLink}
                faqLink={props.faqLink}
                onSettings={() => {
                    setIsVisibleThemeSettings(true);
                }}
                onSignOut={props.onSignOut}
                onClose={onHideAsideNav}
                onGoToTelemetry={onGoToTelemetry}
                isVisibleHomeLink={isVisibleHomeLink()}
                isSocketMonitorOnline={props.isSocketMonitorOnline}
            />
        );
    };

    const renderNotificationsNav = () => {
        if (!notificationsNav.state.isVisible) {
            return null;
        }

        const { isBarVisible } = notificationsNav.state;

        const notifications = {
            data: props.notifications.data || [],
            error: props.notifications.error || "",
            hasMore: props.notifications.hasMore || false,
            isLoading: props.notifications.isLoading || false,
            onLoadMore: props.notifications.onLoadMore,
        };

        return (
            <NotificationsNav
                notifications={notifications}
                bodyScroll={bodyScroll}
                onClose={notificationsNav.hideBar}
                onSelect={props.notifications.onSelect}
                onMarkAsRead={props.notifications.onMarkAsRead}
                isBarVisible={isBarVisible}
                isGlobalCloseDisabled={props.notifications.isGlobalCloseDisabled}
            />
        );
    };

    const renderThemeSettings = () => {
        if (!isVisibleThemeSettings) {
            return null;
        }

        return (
            <PopupThemeSettings
                theme={props.settings.theme}
                onAutoThemeChange={props.onAutoThemeChange}
                onThemeChange={props.onThemeChange}
                onClose={() => {
                    setIsVisibleThemeSettings(false);
                }}
            />
        );
    };

    return (
        <>
            {renderAsideNav()}
            {renderNotificationsNav()}
            {renderThemeSettings()}

            <div data-comment="header-desktop" className={styles.layoutHeader}>
                {renderHeader()}
            </div>

            <div data-comment="content" className={styles.layoutContent}>
                {props.children}
            </div>

            <div data-comment="nav-short" className={styles.layoutMobileNav}>
                <ShortNav
                    pathname={props.pathname}
                    user={props.user}
                    isVisibleHomeLink={isVisibleHomeLink()}
                    sectionsByRoles={props.sectionsByRoles}
                    onSignOut={props.onSignOut}
                />
            </div>
        </>
    );
};

LayoutContainer.defaultProps = {
    pathname: "/",
    appName: "",
    version: "",
    sectionsByRoles: {},
    notificationProgressReport: false,
    notificationsSections: {},
    notifications: {},
    gradesSettings: {},
    classesLimitInfo: {},
    achievements: null,
    avatarIconName: "A",
    user: {},
    settings: {},
    isVisibleMobileHeader: true,
    isSocketMonitorOnline: false,
    disabledMenuGlobalClose: false,
    onAutoThemeChange: () => { },
    onManageAccount: () => { },
    onContactSupport: () => { },
    onEditEmail: () => { },
    onEditPassword: () => { },
    onSaveNewPassword: () => { },
    onSaveNewEmail: () => { },
    onSubscribe: () => { },
    onSignOut: () => { },
};

export default LayoutContainer;
