import text from "@/base/text/index.js";


const loadJuicesDatesByGrades = (services, params = {}) => {
    const { api, actions } = services;
    const { grades } = params;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.juices.setJuicesDatesByGradesLoading({
            grades,
        }));

        const res = await api.dailyJuices.getJuicesDatesByGrade({
            session: state.user.session,
            grades,
        });

        let data = [];
        let error = "";

        if (res.ok) {
            data = res.juices || [];
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.juices.setJuicesDatesByGrades({
            grades,
            data,
            error,
        }));

        return {
            data,
            error,
        };
    };
};

const loadOnceJuicesDatesByGrades = (services, params = {}) => {
    const { grades } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const data = state.juices.juicesDatesByGrades[grades] || {};

        if (data.isLoaded && !data.error) {
            return {
                data: data.data,
            };
        }

        const res = await dispatch(loadJuicesDatesByGrades(services, params));
        return res;
    };
};

const loadJuicesDatesByMonth = (services, params) => {
    const { api, actions } = services;
    const { year, month } = params;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.juices.setJuicesDatesByMonthLoading({
            year,
            month,
        }));

        const res = await api.dailyJuices.getJuicesDates({
            session: state.user.session,
            year,
            month,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.juices || [];
        } else {
            error = "";
        }

        dispatch(actions.juices.setJuicesDatesByMonth({
            year,
            month,
            data,
            error,
        }));
    };
};

const loadOnceJuicesDatesByMonth = (services, params) => {
    const { year, month } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const range = `${year}-${month}`;

        const data = state.juices.juicesDatesByMonth[range] || {};
        const isLoaded = !!data.isLoaded;

        if (isLoaded && !data.error) {
            return;
        }

        dispatch(loadJuicesDatesByMonth(services, params));
    };
};

const loadJuicesDatesByMonthByStudentId = (services, params) => {
    const { api, actions } = services;
    const {
        classId,
        studentId,
        year,
        month,
    } = params;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.juices.setJuicesDatesByMonthByStudentIdLoading({
            studentId,
            year,
            month,
        }));

        const res = await api.dailyJuices.getJuicesDates({
            session: state.user.session,
            classId,
            studentId,
            year,
            month,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.juices || [];
        } else {
            error = "";
        }

        dispatch(actions.juices.setJuicesDatesByMonthByStudentId({
            studentId,
            year,
            month,
            data,
            error,
        }));

        return {
            data,
            error,
        };
    };
};

const loadOnceJuicesDatesByMonthByStudentId = (services, params) => {
    const { studentId, year, month } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const range = `${year}-${month}`;

        const data = state.juices.juicesDatesByMonthByStudentId[studentId]?.[range] || {};
        const isLoaded = !!data.isLoaded;

        if (isLoaded && !data.error) {
            return {
                isAlreadyLoaded: true,
            };
        }

        const res = await dispatch(loadJuicesDatesByMonthByStudentId(services, params));

        return res;
    };
};

const loadJuicesDatesByMonthByGrades = (services, params) => {
    const { api, actions } = services;
    const {
        grades,
        year,
        month,
    } = params;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.juices.setJuicesDatesByMonthByGradesLoading({
            grades,
            year,
            month,
        }));

        const res = await api.dailyJuices.getJuicesDatesByGrade({
            session: state.user.session,
            grades,
            year,
            month,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.juices || [];
        } else {
            error = "";
        }

        dispatch(actions.juices.setJuicesDatesByMonthByGrades({
            grades,
            year,
            month,
            data,
            error,
        }));

        return {
            data,
            error,
        };
    };
};

const loadOnceJuicesDatesByMonthByGrades = (services, params) => {
    const { grades, year, month } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const range = `${year}-${month}`;

        const data = state.juices.juicesDatesByMonthByGrades[grades]?.[range] || {};
        const isLoaded = !!data.isLoaded;

        if (isLoaded && !data.error) {
            return {
                data: data.data,
                error: data.error,
            };
        }

        const res = await dispatch(loadJuicesDatesByMonthByGrades(services, params));

        return res;
    };
};

export default {
    loadJuicesDatesByGrades,
    loadOnceJuicesDatesByGrades,
    loadJuicesDatesByMonth,
    loadOnceJuicesDatesByMonth,
    loadJuicesDatesByMonthByStudentId,
    loadOnceJuicesDatesByMonthByStudentId,
    loadJuicesDatesByMonthByGrades,
    loadOnceJuicesDatesByMonthByGrades,
};
