import * as globalTypes from "@/base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    lastDailyJuice: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_STUDENT_DAILY_JUICES_LAST_DAILY_JUICE_LOADING: {
            return {
                ...state,
                lastDailyJuice: {
                    data: null,
                    error: "",
                    isLoaded: false,
                    isLoading: true,
                },
            };
        }

        case types.SET_STUDENT_DAILY_JUICES_LAST_DAILY_JUICE: {
            const { data, error } = action.payload;

            return {
                ...state,
                lastDailyJuice: {
                    data,
                    error,
                    isLoading: false,
                    isLoaded: true,
                },
            };
        }

        case types.CLEAR_STUDENT_DAILY_JUICES_LAST_DAILY_JUICE: {
            return {
                ...state,
                lastDailyJuice: {},
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
