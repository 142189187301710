import * as types from "./types.js";


export const setAssignmentByGroupCodeLoading = (payload) => ({
    type: types.SET_ASSIGNMENTS_BY_GROUP_CODE_LOADING,
    payload,
});

export const setAssignmentByGroupCode = (payload) => ({
    type: types.SET_ASSIGNMENTS_BY_GROUP_CODE,
    payload,
});

export const setAssignmentByGroupCodeStoryViewed = (payload) => ({
    type: types.SET_ASSIGNMENTS_BY_GROUP_CODE_STORY_VIEWED,
    payload,
});

export const setAssignmentByGroupCodeStoryRate = (payload) => ({
    type: types.SET_ASSIGNMENTS_BY_GROUP_CODE_STORY_RATE,
    payload,
});

export const setAssignmentByGroupCodeStoryExtraJuiceRate = (payload) => ({
    type: types.SET_ASSIGNMENTS_BY_GROUP_CODE_STORY_EXTRA_JUICE_RATE,
    payload,
});

export const setAssignmentByGroupCodeStoryQuizAnswer = (payload) => ({
    type: types.SET_ASSIGNMENTS_BY_GROUP_CODE_STORY_QUIZ_ANSWER,
    payload,
});
