import React, { useRef, useState } from "react";

import classNames from "@/base/lib/class-names.js";

import useGlobalClose from "@/base/hooks/use-global-close/index.js";

import IconChevron from "@/base/icons/chevron/index.js";

import DivButton from "@/base/components/div-button/index.js";
import KeepInViewport from "@/base/components/keep-in-viewport/index.js";

import styles from "./styles.module.css";


const MenuInfoByGroups = (props) => {
    const containerRef = useRef(null);

    const [isMenuVisible, setIsMenuVisible] = useState(false);

    /* --- */

    const toggleMenu = (evt) => {
        const isVisible = !isMenuVisible;

        setIsMenuVisible(isVisible);
        props.onToggle(evt, isVisible);
    };

    /* --- */

    useGlobalClose((evt) => {
        if (!isMenuVisible
            || containerRef.current.contains(evt.target)) {
            return;
        }

        setIsMenuVisible(false);
    }, [isMenuVisible]);

    /* --- */

    const renderMenuLabel = () => {
        let icon = null;

        if (props.menuIcon) {
            icon = (
                <props.menuIcon
                    className={styles.menuLabelIcon}
                    isOrange
                />
            );
        }

        const menuLabelClassName = classNames({
            [styles.menuLabel]: true,
            [styles.menuLabelWithIcon]: !!icon,
        });

        return (
            <DivButton
                onClick={toggleMenu}
                className={menuLabelClassName}
            >
                {icon}
                <div>
                    {props.menuLabel}
                </div>
                <IconChevron
                    className={styles.menuLabelIconChevron}
                    isBlack
                />
            </DivButton>
        );
    };

    const renderMenuGroups = () => {
        if (!props.groups.length) {
            return (
                <div className={styles.menuGroup}>
                    <div className={styles.menuGroupOption}>
                        <div className={styles.menuGroupLabel}>
                            No Options
                        </div>
                    </div>
                </div>
            );
        }

        return props.groups.map((group) => {
            const options = group.options.map((opt) => {
                let label2 = null;

                if (opt.label2) {
                    label2 = (
                        <div className={styles.menuGroupLabel2}>
                            {opt.label2}
                        </div>
                    );
                }

                return (
                    <div className={styles.menuGroupOption}>
                        <div className={styles.menuGroupLabel}>
                            {opt.label}
                        </div>
                        {label2}
                    </div>
                );
            });

            return (
                <div className={styles.menuGroup}>
                    <div className={styles.menuGroupName}>
                        {group.name}
                    </div>
                    {options}
                </div>
            );
        });
    };

    const renderMenu = () => {
        if (!isMenuVisible) {
            return null;
        }

        return (
            <KeepInViewport
                className={styles.menu}
            >
                {renderMenuLabel()}
                <DivButton onClick={props.onMenuClick}>
                    {renderMenuGroups()}
                </DivButton>
            </KeepInViewport>
        );
    };

    const renderLabel = () => {
        let icon = null;

        if (props.labelIcon) {
            const labelIconClassName = classNames({
                [styles.labelIcon]: true,
                [styles.labelIconOpened]: isMenuVisible,
            });

            icon = (
                <props.labelIcon
                    className={labelIconClassName}
                    isOrange
                />
            );
        }

        const labelClassName = classNames({
            [styles.label]: true,
            [styles.labelWithIcon]: !!icon,
        });

        return (
            <DivButton
                className={labelClassName}
                onClick={toggleMenu}
            >
                {props.label}
                {icon}
            </DivButton>
        );
    };

    return (
        <div
            className={styles.container}
            ref={containerRef}
        >
            {renderLabel()}
            {renderMenu()}
        </div>
    );
};

MenuInfoByGroups.defaultProps = {
    label: "label",
    labelIcon: null,
    menuLabel: "Menu label",
    menuIcon: null,
    groups: [],
    onToggle: () => { },
    onMenuClick: () => { },
    isMenuToLeft: false,
};

export default MenuInfoByGroups;
