import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import staticFiles from "@/base/static-files.js";
import classNames from "@/base/lib/class-names.js";

import User from "@/base/project/user.js";
import Urls from "@/base/project/urls.js";

import { getIconByName } from "@/base/icons/index.js";

import IconBook from "@/base/icons/book/index.js";
import IconExclamationInCircle from "@/base/icons/exclamation-in-circle/index.js";
import IconHome from "@/base/icons/home/index.js";
import IconMedal from "@/base/icons/medal/index.js";
import IconMenu from "@/base/icons/menu/index.js";
import IconCheckInArrows from "@/base/icons/check-in-arrows/index.js";
import IconSignIn from "@/base/icons/sign-in/index.js";

import AchievementsWidget from "@/base/business/achievements-widget/index.js";
import Avatar from "@/base/components/avatar/index.js";
import ButtonCircle from "@/base/components/button-circle/index.js";
import ButtonNotification from "@/base/components/button-notification/index.js";
import ButtonWithIcon from "@/base/components/button-with-icon/index.js";
import IconClickable from "@/base/components/icon-clickable/index.js";
import InfoTooltip from "@/base/components/info-tooltip/index.js";
import SelectCustomSmall from "@/base/components/select-custom-small/index.js";

import styles from "./styles.module.css";


const Header = (props) => {
    const roles = useMemo(() => ({
        isTeacher: User.hasRoleTeacher(props.user),
        isParent: User.hasRoleParent(props.user),
        isStudent: User.hasRoleStudent(props.user),
    }), [props.user]);

    /* --- */

    const renderLogo = () => {
        return (
            <>
                <IconClickable
                    onClick={props.onAsideToggle}
                >
                    <IconMenu
                        className={styles.menuIcon}
                        isBlack
                    />
                </IconClickable>
                <div className={styles.logo}>
                    <Link to="/">
                        <img
                            src={staticFiles.juiceHomeLogo}
                            alt={props.name}
                            className={styles.logoImg}
                        />
                    </Link>
                </div>
            </>
        );
    };

    const getProgressReportNotification = () => {
        if (!props.notificationProgressReport) {
            return null;
        }

        return (
            <IconCheckInArrows isGreen />
        );
    };

    const getSectionNotification = (sectionName) => {
        const notification = props.notificationsSections?.[sectionName];

        if (!notification) {
            return null;
        }

        return (
            <InfoTooltip
                tooltip={notification}
                isMinSize
            />
        );
    };

    const renderSections = () => {
        if (!props.user.userId) {
            return null;
        }

        const sections = [];

        User.getNavSections(props.user, props.sectionsByRoles).forEach((sec) => {
            const isSelected = props.pathname.indexOf(sec.to) === 0;

            let icon = null;
            const IconComponent = getIconByName(sec.icon);

            if (IconComponent) {
                icon = (
                    <IconComponent isOrange={isSelected} />
                );
            }

            const sectionClassName = classNames({
                [styles.sectionLink]: true,
                [styles.sectionLinkSelected]: isSelected,
            });

            let notificationReport = null;

            if (Urls.isClass(sec.to)) {
                notificationReport = getProgressReportNotification();
            }

            const notificationSection = getSectionNotification(sec.name);

            const el = (
                <Link
                    className={sectionClassName}
                    to={sec.to}
                    key={sec.name}
                    data-comment={`header-${sec.dataComment}`}
                >
                    {icon}
                    {sec.name}
                    {notificationReport}
                    {notificationSection}
                </Link>
            );

            sections.push(el);
        });

        const isHomeSelected = props.pathname === "/";

        const homeClassName = classNames({
            [styles.sectionLink]: true,
            [styles.sectionLinkSelected]: isHomeSelected,
        });

        let homeLink = null;

        if (props.isVisibleHomeLink) {
            homeLink = (
                <Link
                    to="/"
                    className={homeClassName}
                    data-comment="header-link-home"
                >
                    <IconHome
                        className={styles.sectionIconImg}
                        isOrange={isHomeSelected}
                    />
                    Home
                </Link>
            );
        }

        return (
            <>
                {homeLink}
                {sections}
            </>
        );
    };

    const renderUser = () => {
        if (!props.user.userId) {
            return (
                <Link to="/sign-in" className={styles.signin}>
                    Sign In
                    <IconSignIn isBlack />
                </Link>
            );
        }

        const avatarData = {
            name: props.avatarIconName,
            avatarUrl: props.user.avatarUrl,
            fullname: props.user.fullName,
            username: props.user.userName,
            email: props.user.email,
            daysLeftToExpire: props.user?.daysLeftToExpire || 0,
            isLmsUser: props.user.isLmsUser,
        };

        const isVisibleFreeTrialRow = (roles.isTeacher || roles.isParent)
            && User.isTypeFree(props.user);

        return (
            <Avatar
                data={avatarData}
                onEditPassword={props.onEditPassword}
                onEditEmail={props.onEditEmail}
                onChangeReadingMode={props.onChangeReadingMode}
                onLogout={props.onSignOut}
                onManageAccount={props.onManageAccount}
                onSettings={props.onSettings}
                onContactSupport={props.onContactSupport}
                disabledMenuGlobalClose={props.disabledMenuGlobalClose}
                isVisibleReadingMode={props.isVisibleReadingMode}
                isEnabledReadingMode={props.isEnabledReadingMode}
                isVisibleFreeTrialRow={isVisibleFreeTrialRow}
                isVisibleManageButton={roles.isTeacher || roles.isParent}
            />
        );
    };

    const renderNotificationsButton = () => {
        if (!props.user.userId) {
            return null;
        }

        return (
            <ButtonNotification
                onClick={props.notifications.onToggle}
                isBadgeVisible={props.notifications.isBadgeVisible}
                isActive={props.notifications.isActive}
            />
        );
    };

    const renderClassesLimitInfoButton = () => {
        if (!props.classesLimitInfo.isVisible) {
            return null;
        }

        return (
            <ButtonCircle
                icon={<IconExclamationInCircle isOrange />}
                onClick={props.classesLimitInfo.open}
            />
        );
    };

    const renderGradeSelector = () => {
        if (!roles.isStudent || !props.gradesSettings.isVisible) {
            return null;
        }

        const {
            selected,
            optionsLabel,
            options,
            onSelect,
            isMobile,
            isLoading,
        } = props.gradesSettings;

        return (
            <SelectCustomSmall
                optionsLabel={optionsLabel}
                optionsLabel2="Changes will take effect the next day"
                selected={selected}
                icon={IconBook}
                options={options}
                onSelect={onSelect}
                isIconOnly={isMobile}
                isLoading={isLoading}
                withBorder
            />
        );
    };

    const renderSubscribeButton = () => {
        if (!props.isSubscribeVisible) {
            return null;
        }

        return (
            <ButtonWithIcon
                icon={<IconMedal title="Subscribe" isWhite />}
                onClick={props.onSubscribe}
            >
                Subscribe
            </ButtonWithIcon>
        );
    };

    const renderAchievements = () => {
        const { achievements } = props;

        if (!achievements) {
            return null;
        }

        return (
            <div className={styles.achievements}>
                <AchievementsWidget
                    storiesRead={achievements.storiesRead}
                    questionsAttempted={achievements.questionsAttempted}
                    theme={props.theme}
                    withTooltip
                />
            </div>
        );
    };

    /* --- */

    const headerClassName = classNames({
        [styles.header]: true,
        [styles.headerWithBorder]: props.isBorderVisible,
    });

    const headerStyle = {};

    if (props.notifications.isActive
        && props.bodyScroll.hasScrollableContent) {
        headerStyle.paddingRight = `calc(2rem + ${props.bodyScroll.scrollbarWidth}px)`;
    }

    return (
        <header
            className={headerClassName}
            style={headerStyle}
        >
            <div className={styles.headerLogo}>
                {renderLogo()}
            </div>
            <div className={styles.sections}>
                {renderSections()}
            </div>
            <div className={styles.userControl}>
                {renderAchievements()}
                {renderGradeSelector()}
                {renderNotificationsButton()}
                {renderClassesLimitInfoButton()}
                {renderUser()}
                {renderSubscribeButton()}
            </div>
        </header>
    );
};

Header.defaultProps = {
    name: "",
    user: {},
    avatarIconName: "A",
    pathname: "/",
    sectionsByRoles: {},
    notificationProgressReport: false,
    notificationsSections: {},
    notifications: {},
    gradesSettings: {},
    classesLimitInfo: {},
    achievements: null,
    theme: "",
    bodyScroll: {},

    isSubscribeVisible: false,
    isBorderVisible: true,
    isVisibleHomeLink: true,
    isVisibleReadingMode: false,
    isEnabledReadingMode: false,
    disabledMenuGlobalClose: false,

    onEditPassword: () => { },
    onEditEmail: () => { },
    onManageAccount: () => { },
    onContactSupport: () => { },

    onSettings: () => { },
    onSubscribe: () => { },
    onAsideToggle: () => { },
    onSignOut: () => { },
};

export default Header;
