import React, { useEffect, useState, useRef } from "react";

import classNames from "@/base/lib/class-names.js";

import useDimensions from "@/base/hooks/use-dimensions/index.js";

import styles from "./styles.module.css";


const OFFSET_PX = 30;

const KeepInViewport = (props) => {
    const [translateX, setTranslateX] = useState(0);

    const containerRef = useRef(null);

    const dimensions = useDimensions();

    /* --- */

    const getContainerStyle = () => {
        const style = {};

        if (translateX) {
            style.transform = `translateX(${translateX}px)`;
        }

        return style;
    };

    /* --- */

    const measureOffset = () => {
        let transX = 0;

        const rect = containerRef.current?.getBoundingClientRect?.() || {};

        const rightOffset = (rect.right || 0) - dimensions.width;
        const leftOffset = rect.left || 0;

        if (rightOffset > 0) {
            transX = (rightOffset + OFFSET_PX) * -1;
        } else if (leftOffset < 0) {
            transX = (leftOffset - OFFSET_PX) * -1;
        }

        setTranslateX(transX);
    };

    /* --- */

    useEffect(() => {
        setTranslateX(0);

        const timer = globalThis.setTimeout(measureOffset, 10);

        return () => {
            if (timer) {
                globalThis.clearInterval(timer);
            }
        };
    }, [dimensions.width]);

    /* --- */

    const containerClassName = classNames({
        [styles.containerWithSmoothTransition]: !!translateX,
        [props.className]: !!props.className,
    });

    const containerStyle = getContainerStyle();

    return (
        <div
            ref={containerRef}
            className={containerClassName}
            style={containerStyle}
        >
            {props.children}
        </div>
    );
};

KeepInViewport.defaultProps = {
    className: "",
    children: null,
};

export default KeepInViewport;
