import storage from "@/base/lib/storage/index.js";

import Urls from "@/base/project/urls.js";

import actions from "@/base/store/actions.js";
import actionsDistrictAdminReloader from "@/base/actions/district-admin-reloader.js";
import actionsDistrictAdminClassAchievements from "@/base/actions/district-admin-class-achievements.js";
import actionsDistrictAdminClassDailyJuices from "@/base/actions/district-admin-class-daily-juices.js";
import actionsDistrictAdminAssignments from "@/base/actions/district-admin-class-assignments.js";
import actionsUser from "@/base/actions/user.js";

import monitorCommands from "@/app/monitors/commands.js";

import app from "@/app/app.js";
import api from "@/app/api.js";
import events from "@/app/events.js";


const MONITOR_COMMANDS = monitorCommands.getMonitorCommands();

function getDistrictAdminCallbacks(store) {
    const services = { api, actions, events, storage };

    return {
        reloadDashboard() {
            store.dispatch(actionsDistrictAdminReloader.reloadDashboard(services));
        },

        reloadClass() {
            store.dispatch(actionsDistrictAdminReloader.reloadClass(services));
        },

        reloadClassDailyJuicesScores() {
            store.dispatch(actionsDistrictAdminClassDailyJuices.loadOrReloadDailyJuicesScores(services));
        },

        reloadClassAssignmentsScores() {
            store.dispatch(actionsDistrictAdminAssignments.loadOrReloadAssignmentsScores(services));
        },

        reloadClassStudentAchievements() {
            store.dispatch(actionsDistrictAdminClassAchievements.loadOrReloadStudentsAchievements(services));
        },

        setStoryRating(data) {
            store.dispatch(actions.juiceStories.setStoryInfoStoryRate(data));
        },

        setExtraJuiceRating(data) {
            store.dispatch(actions.juiceStories.setStoryInfoStoryExtraJuiceRate(data));
        },

        userLogoutBySession(data) {
            store.dispatch(actionsUser.signOutBySession(services, data));
        },

        /* --- */

        onUpdate(data) {
            if (data.cmd === MONITOR_COMMANDS.DJ_IN_PROGRESS
                || data.cmd === MONITOR_COMMANDS.DJ_COMPLETED) {
                this.reloadDashboard();
            } else if (data.cmd === MONITOR_COMMANDS.CLASS_STUDENTS_UPDATED) {
                this.reloadClass();
            } else if (data.cmd === MONITOR_COMMANDS.QUIZ_ANSWERED) {
                this.reloadClassDailyJuicesScores();
            } else if (data.cmd === MONITOR_COMMANDS.ASSIGNMENT_ANSWERED) {
                this.reloadClassAssignmentsScores();
            } else if (data.cmd === MONITOR_COMMANDS.STUDENT_UPDATE_ACHIEVEMENT) {
                this.reloadClassStudentAchievements();
            } else if (data.cmd === MONITOR_COMMANDS.STORY_RATING_UPDATED) {
                this.setStoryRating(data.payload || {});
            } else if (data.cmd === MONITOR_COMMANDS.EXTRA_JUICE_RATING_UPDATED) {
                this.setExtraJuiceRating(data.payload || {});
            } else if (data.cmd === MONITOR_COMMANDS.USER_LOGOUT) {
                this.userLogoutBySession(data.payload || {});
            } else {
                // eslint-disable-next-line no-console
                console.log("[WS]: Unknown command ", data);
            }
        },

        onUpdateAll() {
            this.reloadDashboard();
            this.reloadClass();
        },

        onReconnectedAfterOffline() {
            const state = store.getState();
            const navigation = state.navigation || {};
            const urlParams = navigation.params || {};
            const pathname = navigation.location?.pathname || "";

            if (Urls.isTeacherDailyJuices(pathname) || Urls.isDailyJuices(pathname)) {
                app.actions.student.dailyJuices.loadJuiceStoriesInfoById({
                    juiceId: urlParams.juiceId || "",
                });
            } else if (Urls.isClassDistrictAdmin(pathname)) {
                this.reloadClass();
                this.reloadClassStudentAchievements();
            } else if (Urls.isDashboardDistrictAdmin(pathname)) {
                this.reloadDashboard();
            }
        },
    };
}

export default {
    getDistrictAdminCallbacks,
};
