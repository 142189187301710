import React, { lazy } from "react";
import { Navigate } from "react-router-dom";

import settings from "@/app/settings.js";

import Index from "@/app/views/index/index.js";
import NotFound from "@/app/views/not-found/index.js";

const UserSignIn = lazy(() => import("@/app/views/user/sign-in/index.js"));
const UserSignInLMS = lazy(() => import("@/app/views/user/sign-in-lms/index.js"));

const UserSignUp = lazy(() => import("@/app/views/user/sign-up/index.js"));
const UserSignUpTrial = lazy(() => import("@/app/views/user/sign-up-trial/index.js"));
const UserPasswordForgot = lazy(() => import("@/app/views/user/password-forgot/index.js"));
const UserPasswordChange = lazy(() => import("@/app/views/user/password-change/index.js"));
const UserProfile = lazy(() => import("@/app/views/user/profile/index.js"));

const Purchase = lazy(() => import("@/app/views/purchase/index.js"));

const DailyJuiceStory = lazy(() => import("@/app/views/daily-juice-story/index.js"));

const DailyJuice = lazy(() => import("@/app/views/daily-juice/index.js"));
const DailyJuices = lazy(() => import("@/app/views/daily-juices/index.js"));

const DailyJuicesTeacher = lazy(() => import("@/app/views/daily-juices-teacher/index.js"));

const Resources = lazy(() => import("@/app/views/resources/index.js"));

const AssignmentStory = lazy(() => import("@/app/views/assignment-story/index.js"));
const Assignment = lazy(() => import("@/app/views/assignment/index.js"));
const Assignments = lazy(() => import("@/app/views/assignments/index.js"));

const ClassDistrictAdmin = lazy(() => import("@/app/views/class-district-admin/index.js"));
const DashboardDistrictAdmin = lazy(() => import("@/app/views/dashboard-district-admin/index.js"));

const DashboardAdmin = lazy(() => import("@/app/views/dashboard-admin/index.js"));
const ClassAdmin = lazy(() => import("@/app/views/class-admin/index.js"));

const Class = lazy(() => import("@/app/views/class/index.js"));
const ClassSettings = lazy(() => import("@/app/views/class-settings/index.js"));

const Vocabulary = lazy(() => import("@/app/views/vocabulary/index.js"));
const VocabularyWord = lazy(() => import("@/app/views/vocabulary-word/index.js"));

const Analytics = lazy(() => import("@/app/views/analytics/index.js"));

const Dashboard = lazy(() => import("@/app/views/dashboard/index.js"));

const Student = lazy(() => import("@/app/views/student/index.js"));

const Search = lazy(() => import("@/app/views/search/index.js"));

const Story = lazy(() => import("@/app/views/story/index.js"));
const ExtraJuice = lazy(() => import("@/app/views/extra-juice/index.js"));

const Subscribe = lazy(() => import("@/app/views/subscribe/index.js"));
const SubscribeByPlan = lazy(() => import("@/app/views/subscribe-by-plan/index.js"));

const Notification = lazy(() => import("@/app/views/notification/index.js"));

const GeneratePdf = lazy(() => import("@/app/views/generate-pdf/index.js"));

const DownloadScoreReport = lazy(() => import("@/app/views/download-score-report/index.js"));

const Page = lazy(() => import("@/app/views/page/index.js"));

const Debug = lazy(() => import("@/app/views/debug/index.js"));

const Unsubscribe = lazy(() => import("@/app/views/unsubscribe/index.js"));

// TODO: unfinished lazy loading
// eslint-disable-next-line import/first
import studentRoutes from "./student.js";


const classViewParams = ":dateFrom/:dateToOrLoadType/:view";

export default [
    {
        path: "",
        element: <Index />,
    },

    // NOTE: purchase and sign up, "role" param is to support old links
    {
        path: "purchase",
        element: <Purchase />,
    },
    {
        path: "purchase/:referralCode",
        element: <Purchase />,
    },
    {
        path: "purchase/:referralCode/:role",
        element: <Purchase />,
    },

    // NOTE: roles sign up via referral code, "role" param is to support old links
    {
        path: "sign-up/trial/:role/:referralCode",
        element: <UserSignUpTrial />,
    },
    {
        path: "sign-up/trial/:role",
        element: <UserSignUpTrial />,
    },
    {
        path: "sign-up/trial",
        element: <UserSignUpTrial />,
    },

    // NOTE: student sign up by class code
    {
        path: "user/sign-up/:code",
        element: <UserSignUp />,
    },
    {
        path: "user/sign-up",
        element: <UserSignUp />,
    },

    {
        path: "user/login",
        element: <Navigate to="/user/sign-in" replace />,
    },
    {
        path: "user/sign-in",
        element: <Navigate to="/sign-in" />,
    },
    {
        path: "user/forgot-password",
        element: <Navigate to="/forgot-password" />,
    },

    {
        path: "sign-in",
        element: <UserSignIn />,
    },
    {
        path: "sign-in/lms",
        element: <UserSignInLMS />,
    },
    {
        path: "forgot-password",
        element: <UserPasswordForgot />,
    },

    {
        path: "user/set-password/:secretKey",
        element: <UserSignIn />,
    },
    {
        path: "user/reset-password/:secretKey",
        element: <UserSignIn />,
    },

    // NOTE: teacher subscribes to plan
    {
        path: "subscribe/:planSlugOrCode",
        element: <SubscribeByPlan />,
    },
    {
        path: "subscribe/private/:planSlugOrCode",
        element: <SubscribeByPlan />,
    },
    {
        path: "subscribe",
        element: <Subscribe />,
    },

    // TODO: change aside nav to open new profile
    {
        path: "user/profile",
        element: <UserProfile />,
    },
    {
        path: "user/change-password",
        element: <UserPasswordChange />,
    },

    {
        path: "assignments/:groupCode/:storyId",
        element: <AssignmentStory />,
    },
    {
        path: "assignments/:groupCode",
        element: <Assignment />,
    },
    {
        path: "assignments",
        element: <Assignments />,
    },

    {
        path: "daily-juices/:juiceId/:storyId",
        element: <DailyJuiceStory />,
    },
    {
        path: "daily-juices/:juiceId",
        element: <DailyJuice />,
    },
    {
        path: "daily-juices",
        element: <DailyJuices />,
    },

    {
        path: "teacher-daily-juices/:juiceId",
        element: <DailyJuicesTeacher />,
    },
    {
        path: "teacher-daily-juices",
        element: <DailyJuicesTeacher />,
    },

    {
        path: "resources",
        element: <Resources />,
    },

    {
        path: `daily-juice-preview-${settings.previewCode}/:juiceId/:storyId`,
        element: <DailyJuiceStory />,
    },
    {
        path: `daily-juice-preview-${settings.previewCode}/:juiceId`,
        element: <DailyJuice />,
    },

    {
        path: "analytics/:dateFrom/:dateTo/:school/:department/:teacher",
        element: <Analytics />,
    },
    {
        path: "analytics/:dateFrom/:dateTo/:school/:department",
        element: <Analytics />,
    },
    {
        path: "analytics/:dateFrom/:dateTo/:school",
        element: <Analytics />,
    },
    {
        path: "analytics/:dateFrom/:dateTo",
        element: <Analytics />,
    },
    {
        path: "analytics",
        element: <Analytics />,
    },

    {
        path: "dashboard/:tab/:dateFrom/:dateTo/:categories/:chartType/:grades",
        element: <Dashboard />,
    },
    {
        path: "dashboard/:tab",
        element: <Dashboard />,
    },
    {
        path: "dashboard",
        element: <Dashboard />,
    },

    {
        path: "admin/dashboard/:district/:school/:department/:classId/:tab/:dateFrom/:dateTo/:categories/:chartType/:grades",
        element: <DashboardAdmin />,
    },
    {
        path: "admin/dashboard/:district/:school/:department/:classId/:tab",
        element: <DashboardAdmin />,
    },
    {
        path: "admin/dashboard/:district/:school/:department/:classId",
        element: <DashboardAdmin />,
    },
    {
        path: "admin/dashboard/:district/:school/:department",
        element: <DashboardAdmin />,
    },
    {
        path: "admin/dashboard/:district/:school",
        element: <DashboardAdmin />,
    },
    {
        path: "admin/dashboard/:district",
        element: <DashboardAdmin />,
    },
    {
        path: "admin/dashboard",
        element: <DashboardAdmin />,
    },

    {
        path: "district-admin/dashboard/:school/:department/:classId/:tab/:dateFrom/:dateTo/:categories/:chartType/:grades",
        element: <DashboardDistrictAdmin />,
    },
    {
        path: "district-admin/dashboard/:school/:department/:classId/:tab",
        element: <DashboardDistrictAdmin />,
    },
    {
        path: "district-admin/dashboard/:school/:department/:classId",
        element: <DashboardDistrictAdmin />,
    },
    {
        path: "district-admin/dashboard/:school/:department",
        element: <DashboardDistrictAdmin />,
    },
    {
        path: "district-admin/dashboard/:school",
        element: <DashboardDistrictAdmin />,
    },
    {
        path: "district-admin/dashboard",
        element: <DashboardDistrictAdmin />,
    },

    {
        path: "story/:storyId",
        element: <Story />,
    },
    {
        path: "extra-juice/:extraJuiceId",
        element: <ExtraJuice />,
    },

    // NOTE: this route to solve missing dates conflict
    {
        path: "class/progress/*",
        element: <Class />,
        children: studentRoutes,
    },
    {
        path: `class/${classViewParams}/*`,
        element: <Class />,
        children: studentRoutes,
    },
    {
        path: `class/${classViewParams}`,
        element: <Class />,
        children: studentRoutes,
    },
    {
        path: "class/:view",
        element: <Class />,
        children: studentRoutes,
    },
    {
        path: "class",
        element: <Class />,
    },

    // NOTE: this route to solve missing dates conflict
    {
        path: "admin/class/:district/:school/:department/:classId/progress/*",
        element: <ClassAdmin />,
        children: studentRoutes,
    },
    {
        path: `admin/class/:district/:school/:department/:classId/${classViewParams}/*`,
        element: <ClassAdmin />,
        children: studentRoutes,
    },
    {
        path: `admin/class/:district/:school/:department/:classId/${classViewParams}`,
        element: <ClassAdmin />,
        children: studentRoutes,
    },
    {
        path: "admin/class/:district/:school/:department/:classId/:view",
        element: <ClassAdmin />,
    },
    {
        path: "admin/class/:district/:school/:department/:classId",
        element: <ClassAdmin />,
    },
    {
        path: "admin/class/:district/:school/:department",
        element: <ClassAdmin />,
    },
    {
        path: "admin/class/:district/:school",
        element: <ClassAdmin />,
    },
    {
        path: "admin/class/:district",
        element: <ClassAdmin />,
    },
    {
        path: "admin/class",
        element: <ClassAdmin />,
    },

    // NOTE: this route to solve missing dates conflict
    {
        path: "district-admin/class/:school/:department/:classId/progress/*",
        element: <ClassDistrictAdmin />,
        children: studentRoutes,
    },
    {
        path: `district-admin/class/:school/:department/:classId/${classViewParams}/*`,
        element: <ClassDistrictAdmin />,
        children: studentRoutes,
    },
    {
        path: `district-admin/class/:school/:department/:classId/${classViewParams}`,
        element: <ClassDistrictAdmin />,
        children: studentRoutes,
    },
    {
        path: "district-admin/class/:school/:department/:classId/:view",
        element: <ClassDistrictAdmin />,
        children: studentRoutes,
    },
    {
        path: "district-admin/class/:school/:department/:classId",
        element: <ClassDistrictAdmin />,
    },
    {
        path: "district-admin/class/:school/:department",
        element: <ClassDistrictAdmin />,
    },
    {
        path: "district-admin/class/:school",
        element: <ClassDistrictAdmin />,
    },
    {
        path: "district-admin/class",
        element: <ClassDistrictAdmin />,
    },

    {
        path: "class-settings",
        element: <ClassSettings />,
    },

    {
        path: "search",
        element: <Search />,
    },

    {
        path: "vocabulary/:wordId",
        element: <VocabularyWord />,
    },
    {
        path: "vocabulary",
        element: <Vocabulary />,
    },

    {
        path: "student",
        element: <Student />,
    },

    {
        path: "notification/:notificationId",
        element: <Notification />,
    },

    {
        path: "generate/pdf",
        element: <GeneratePdf />,
    },

    {
        path: "download/score-report",
        element: <DownloadScoreReport />,
    },
    {
        path: "download/score-report/:id",
        element: <DownloadScoreReport />,
    },

    {
        path: "page/:slug",
        element: <Page />,
    },

    {
        path: "telemetry",
        element: <Debug />,
    },

    {
        path: "unsubscribe",
        element: <Unsubscribe />,
    },

    {
        path: "*",
        element: <NotFound />,
    },
];
