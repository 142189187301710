import React, { useEffect, useState, useRef } from "react";

import classNames from "@/base/lib/class-names.js";

import useDimensions from "@/base/hooks/use-dimensions/index.js";
import SelectCustom from "@/base/components/select-custom/index.js";
import TooltipInfoWithIcon from "@/base/components/tooltip-info-with-icon/index.js";

import styles from "./styles.module.css";


const Tabs = (props) => {
    const tabsElements = useRef([]);

    const [selectedTab, setSelectedTab] = useState(null);
    const [isDropdownView, setDropdownView] = useState(false);

    const dimensions = useDimensions();

    useEffect(() => {
        if (selectedTab !== props.selectedTab) {
            setSelectedTab(props.selectedTab);
        }
    }, [props.selectedTab]);

    const isEllipsisActive = (evt) => {
        return evt.offsetWidth < evt.scrollWidth;
    };

    useEffect(() => {
        if (props.onlyTabs) {
            return;
        }

        let isTextOverflowed = false;

        for (let i = 0; i < tabsElements.current.length; i += 1) {
            if (tabsElements.current[i]) {
                if (isEllipsisActive(tabsElements.current[i])) {
                    isTextOverflowed = true;
                    break;
                }
            }
        }

        setDropdownView(isTextOverflowed);
    }, [dimensions]);

    const onChange = (value, isDisabled) => {
        if (isDisabled) {
            return;
        }

        setSelectedTab(value);
        props.onChange(value);
    };

    const renderTooltip = (value) => {
        if (!props.tooltips[value]) {
            return null;
        }

        return (
            <TooltipInfoWithIcon
                tooltip={{
                    message: props.tooltips[value],
                }}
                isMinSize
            />
        );
    };

    const renderTabs = () => {
        const tabs = props.tabs.map((tab, index) => {
            const isSelected = selectedTab === tab.value;
            const { isDisabled } = tab;

            let icon = null;

            if (tab.icon) {
                icon = (
                    <tab.icon
                        isOrange={isSelected}
                    />
                );
            }

            const tabClassName = classNames({
                [styles.tab]: true,
                [styles.tabSelected]: isSelected,
                [styles.tabDisabled]: isDisabled,
                [styles.tabWhite]: props.isWhiteTheme,
                [styles.tabBlueSelected]: props.isBlueTheme && isSelected,
                [styles.tabRoseSelected]: props.isRoseTheme && isSelected,
                [styles.tabWhiteSelected]: props.isWhiteTheme && isSelected,
            });

            return (
                <div
                    key={tab.value}
                    className={tabClassName}
                    onClick={() => {
                        onChange(tab.value, isDisabled);
                    }}
                    onKeyPress={() => {
                        onChange(tab.value, isDisabled);
                    }}
                    role="button"
                    tabIndex="-1"
                    data-comment={tab.dataComment || ""}
                >
                    {icon}
                    <div
                        className={styles.tabTitle}
                        ref={(currElem) => {
                            tabsElements.current[index] = currElem;
                        }}
                    >
                        {tab.label}
                    </div>
                    {renderTooltip(tab.value)}
                </div>
            );
        });

        const tabsClassName = classNames({
            [styles.tabs]: true,
            [styles.hiddenTabs]: isDropdownView,
        });

        const tabsCommonClassName = classNames({
            [styles.elementHidden]: isDropdownView,
        });

        return (
            <div className={tabsCommonClassName}>
                <div className={tabsClassName}>
                    {tabs}
                </div>
            </div>
        );
    };

    const renderDropdown = () => {
        if (!isDropdownView || props.onlyTabs) {
            return null;
        }

        const options = props.tabs.map((tab) => {
            let iconBefore = null;

            if (tab.icon) {
                iconBefore = <tab.icon />;
            }

            return {
                value: tab.value,
                label: tab.label,
                iconBefore,
            };
        });

        return (
            <SelectCustom
                selected={selectedTab}
                options={options}
                onSelect={onChange}
                withShadow
            />
        );
    };

    return (
        <div className={styles.tabsCommon} data-comment={props.dataComment}>
            {renderTabs()}
            {renderDropdown()}
        </div>
    );
};

Tabs.defaultProps = {
    dataComment: "",
    tabs: [],
    selectedTab: 0,
    tooltips: {},
    onChange: () => { },
    isBlueTheme: false,
    isRoseTheme: false,
    isWhiteTheme: false,
    onlyTabs: false,
};

export default Tabs;
