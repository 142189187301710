import React from "react";

import IconInformation from "@/base/icons/information/index.js";

import TooltipInfoWithIcon from "@/base/components/tooltip-info-with-icon/index.js";


const TOOLTIP_POSITIONS = {
    topLeft: "topLeft",
    topRight: "topRight",
    bottomRight: "bottomRight",
    bottomLeft: "bottomLeft",
};

const InfoTooltip = (props) => {
    const getPositionX = () => {
        if (props.position === TOOLTIP_POSITIONS.bottomLeft
            || props.position === TOOLTIP_POSITIONS.topLeft) {
            return "left";
        }

        if (props.position === TOOLTIP_POSITIONS.bottomRight
            || props.position === TOOLTIP_POSITIONS.topRight) {
            return "right";
        }

        return "";
    };

    const getPositionY = () => {
        if (props.position === TOOLTIP_POSITIONS.topRight
            || props.position === TOOLTIP_POSITIONS.topLeft) {
            return "top";
        }

        return "bottom";
    };

    /* --- */

    const tooltip = {
        message: props.tooltip,
        positionX: getPositionX(),
        positionY: getPositionY(),
    };

    return (
        <TooltipInfoWithIcon
            tooltip={tooltip}
            icon={IconInformation}
            isMinSize
            isIconJumping
            isRed
        />
    );
};

InfoTooltip.defaultProps = {
    tooltip: "",
    position: "",
};

export default InfoTooltip;
