export default (apiURL, methods) => {
    return {
        getTeacherClassScoresByAssignments(params = {}) {
            // NOTE: action=get_teacher_class_last_assignments_by_date
            const url = `${apiURL}/teacher/classes/${params.classId}/scores/by-assignments`;

            return methods.get3(url, {
                session_id: params.session,
                date: params.date,
                load_type: params.loadType,
            });
        },

        getTeacherClassScoresByDailyJuices(params = {}) {
            // NOTE: action=get_teacher_class_students_daily_juices_scores
            const url = `${apiURL}/classes/${params.classId}/scores/by-daily-juices`;

            return methods.get3(url, {
                session_id: params.session,
                date_from: params.dateFrom,
                date_to: params.dateTo,
            });
        },

        updateTeacherClassEndDate(params = {}) {
            // NOTE: action=class_update_end_date
            const url = `${apiURL}/teacher/classes/${params.classId}/end-date`;

            return methods.post(url, {
                session_id: params.session,
                class_id: params.classId,
                end_date: params.endDate,
            });
        },

        /* --- */

        updateSecondaryTeacherBadEmail(params = {}) {
            // NOTE: action=teacher_update_secondary_teacher_bad_email
            const url = `${apiURL}/classes/${params.classId}/teachers/bad-email`;

            return methods.post(url, {
                session_id: params.session || "",
                class_id: params.classId || "",
                secondary_teacher_id: params.teacherId || "",
                email: params.email || "",
            });
        },

        /* --- */

        getClassStudentsGradesByRange(params = {}) {
            // NOTE: action=get_class_students_grades_by_range
            const url = `${apiURL}/teacher/classes/${params.classId}/students/grades`;

            return methods.get3(url, {
                session_id: params.session,
                date_from: params.dateFrom,
                date_to: params.dateTo,
            });
        },

        /* --- */

        getStudentsAchievements(params = {}) {
            // NOTE: action=get_class_students_achievements
            const url = `${apiURL}/teacher/classes/${params.classId}/achievements`;

            return methods.get3(url, {
                session_id: params.session || "",
            });
        },

        /* --- */

        getDerosteredClasses(params = {}) {
            // NOTE: action=class_get_derostered_classes_by_teacher
            const url = `${apiURL}/teacher/classes/derostered`;

            return methods.get3(url, {
                session_id: params.session || "",
            });
        },

        restoreClass(params = {}) {
            // NOTE: action=class_restore_derostered_class_by_teacher
            const url = `${apiURL}/teacher/classes/derostered`;

            return methods.post(url, {
                session_id: params.session || "",
                derostering_id: params.derosteringId || "",
            });
        },
    };
};
