import React from "react";

import HorizontalScrollingMenu, { Chip } from "@/base/components/horizontal-scrolling-menu/index.js";


const MenuHorizontalScrolling = (props) => {
    const renderChips = ({ dragging, handleItemClick }) => {
        const items = [];

        if (props.menuItemsLeftArrow.isVisible) {
            const itemId = "chip-item-arrow-left";

            items.push(
                <Chip
                    key={itemId}
                    itemId={itemId}
                    icon={props.menuItemsLeftArrow.icon}
                    label={props.menuItemsLeftArrow.label}
                    onClick={() => {
                        if (!dragging && !props.menuItemsLeftArrow.isLoading) {
                            props.menuItemsLeftArrow.onClick();
                        }
                    }}
                    withCursorPointer={!dragging}
                    disabled={props.menuItemsLeftArrow.isLoading}
                    isLoading={props.menuItemsLeftArrow.isLoading}
                    isRoseTheme={props.isRoseTheme}
                />,
            );
        }

        for (let i = 0; i < props.items.length; i += 1) {
            const item = props.items[i];
            const itemId = `chip-item-${item.value}`;

            const isSelected = props.selected === item.value;

            items.push(
                <Chip
                    selected={isSelected}
                    key={itemId}
                    itemId={itemId}
                    label={item.label}
                    onClick={handleItemClick({
                        value: item.value,
                        itemId,
                    })}
                    withCursorPointer={!dragging}
                    isRoseTheme={props.isRoseTheme}
                />,
            );
        }

        return items;
    };

    // NOTE: The "selected" prop must be the same as
    //       the Tab "key" prop(to scroll to selected tab onInit)
    return (
        <HorizontalScrollingMenu
            dataComment={props.dataComment}
            selected={`chip-item-${props.selected}`}
            onSelect={props.onSelect}
            hideArrows={props.hideArrows}
        >
            {renderChips}
        </HorizontalScrollingMenu>
    );
};

MenuHorizontalScrolling.defaultProps = {
    dataComment: "menu-horizontal-scrolling",
    selected: null,
    items: [],
    menuItemsLeftArrow: {
        icon: null,
        label: "",
        onClick: () => { },
        isLoading: false,
        isVisible: false,
    },
    onSelect: () => { },
    hideArrows: true,
    isRoseTheme: false,
};

export default MenuHorizontalScrolling;
