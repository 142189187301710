import React, { useRef, useState } from "react";

import classNames from "@/base/lib/class-names.js";

import useGlobalClose from "@/base/hooks/use-global-close/index.js";

import IconCaret from "@/base/icons/caret/index.js";
import IconInformation from "@/base/icons/information/index.js";

import TooltipInfoWithIcon from "@/base/components/tooltip-info-with-icon/index.js";
import DivButton from "@/base/components/div-button/index.js";
import OptionWithLabels from "./option-with-labels.js";
import Option from "./option.js";

import scrollbarStyles from "@/base/components/scrollbar/styles.module.css";
import styles from "./styles.module.css";


const SelectCustom = (props) => {
    const selectCustom = useRef(null);

    const [isOpened, setIsOpened] = useState(false);

    /* --- */

    const getSelectedOption = () => {
        for (let i = 0; i < props.options.length; i += 1) {
            const opt = props.options[i];

            if (opt.value === props.selected) {
                return opt;
            }
        }

        return null;
    };

    /* --- */

    const onToggle = () => {
        if (props.disabled) {
            return;
        }

        setIsOpened((val) => !val);
    };

    const onSelect = (evt, opt) => {
        setIsOpened(false);
        props.onSelect(opt.value);
    };

    const onSelectedValueIconClick = (evt) => {
        evt.stopPropagation();
        props.onIconClick();
    };

    /* --- */

    useGlobalClose((evt) => {
        if (selectCustom?.current?.contains(evt.target)) {
            return;
        }

        setIsOpened(false);
    }, []);

    /* --- */

    const renderSelectedValueIcon = (iconType) => {
        if (props.tooltip) {
            return (
                <TooltipInfoWithIcon
                    tooltip={{
                        message: props.tooltip,
                    }}
                    isMinSize
                />
            );
        }

        if (iconType === "info") {
            return (
                <DivButton
                    className={styles.optionSelectedValueIcon}
                    onClick={onSelectedValueIconClick}
                >
                    <IconInformation
                        className={styles.optionSelectedValueIconImg}
                        isSky
                    />
                </DivButton>
            );
        }

        return null;
    };

    const renderOptionSelectedIcon = () => {
        if (!props.icon) {
            return null;
        }

        return (
            <div className={styles.optionSelectedIcon}>
                {props.icon}
            </div>
        );
    };

    const renderOptionSelectedCaret = () => {
        const imgClassName = classNames({
            [styles.optionCaretImg]: true,
            [styles.optionSelectedCaretImgUp]: isOpened,
        });

        return (
            <div className={styles.optionSelectedCaret}>
                <IconCaret
                    className={imgClassName}
                />
            </div>
        );
    };

    const renderOptionSelectedValue = () => {
        const selectedOpt = getSelectedOption();

        const label = selectedOpt?.label
            || props.defaultValueLabel;

        const iconAfter = selectedOpt?.iconAfter || null;

        if (props.withLabel2 || props.withSelectOptionLabel2) {
            let label2 = null;

            if (selectedOpt?.label2) {
                label2 = (
                    <div className={styles.selectedValueLabel2}>
                        {selectedOpt.label2 }
                    </div>
                );
            }

            return (
                <div className={styles.selectedValue}>
                    <div className={styles.selectedValueLabelContainer}>
                        <div className={styles.selectedValueLabels}>
                            <div>
                                {label}
                            </div>
                            {label2}
                        </div>
                        {iconAfter}
                    </div>
                    {renderSelectedValueIcon(selectedOpt?.icon || "")}
                </div>
            );
        }

        return (
            <div className={styles.selectedValue}>
                <div className={styles.selectedValueLabelContainer}>
                    <div className={styles.selectedValueLabel}>
                        {label}
                    </div>
                    {iconAfter}
                </div>
                {renderSelectedValueIcon(selectedOpt?.icon || "")}
            </div>
        );
    };

    const renderOptions = () => {
        if (!isOpened) {
            return null;
        }

        let options = [];

        if (props.options.length === 0) {
            const optClassName = classNames({
                [styles.option]: true,
                [styles.optionWithIcon]: !!props.icon,
            });

            options.push(
                <DivButton
                    className={optClassName}
                    onClick={onToggle}
                >
                    {props.noOptionsText}
                </DivButton>,
            );
        } else {
            options = props.options.map((opt) => {
                const isSelected = props.selected === opt.value;

                if (props.withLabel2) {
                    return (
                        <OptionWithLabels
                            option={opt}
                            isSelected={isSelected}
                            withIcon={props.icon}
                            onSelect={onSelect}
                        />
                    );
                }

                return (
                    <Option
                        key={`option-${opt.value}`}
                        option={opt}
                        isSelected={isSelected}
                        withIcon={props.icon}
                        orangeTheme={props.orangeTheme}
                        small={props.small}
                        onSelect={onSelect}
                    />
                );
            });
        }

        const optionsNum = options.length;

        const optionsClassName = classNames({
            [styles.options]: true,
            [styles.optionsUp]: props.isDropUp,
            [styles.optionsWithBorderRadius]: props.withBorderRadius,
            [styles.optionsWithScroll]: optionsNum > 4 && props.withScroll,
            [scrollbarStyles.scrollbar]: optionsNum > 4 && props.withScroll,
        });

        return (
            <div className={optionsClassName}>
                {options}
            </div>
        );
    };

    const selectClassName = classNames({
        [styles.select]: true,
        [styles.selectMaxHeight]: props.isMaxHeight,
        [styles.selectWithBorderRadius]: props.withBorderRadius,
        [styles.selectWithBorder]: props.withBorder,
        [styles.selectWithShadow]: props.withShadow,
        [styles.selectDisabled]: props.disabled,
    });

    const optSelectedClassName = classNames({
        [styles.optionSelected]: true,
        [styles.optionSelectedMaxHeight]: props.isMaxHeight,
        [styles.optionSelectedWithBorderRadius]: props.withBorderRadius,
        [styles.optionSelectedSmall]: props.small,
        [styles.optionSelectedWithIcon]: props.icon,
        [styles.optionSelectedDisabled]: props.disabled,
    });

    return (
        <div
            ref={selectCustom}
            className={selectClassName}
            data-comment={props.dataComment}
        >
            <DivButton
                className={optSelectedClassName}
                onClick={onToggle}
            >
                {renderOptionSelectedIcon()}
                {renderOptionSelectedValue()}
                {renderOptionSelectedCaret()}
            </DivButton>
            {renderOptions()}
        </div>
    );
};

SelectCustom.defaultProps = {
    selected: null,
    options: [],
    icon: null,
    defaultValueLabel: "Select ...",
    noOptionsText: "No options",
    dataComment: "",
    tooltip: "",
    onSelect: () => { },
    onIconClick: () => { },
    disabled: false,
    withLabel2: false,
    withSelectOptionLabel2: false,
    withScroll: true,
    withBorder: true,
    withShadow: false,
    orangeTheme: false,
    withBorderRadius: false,
    small: false,
    isDropUp: false,
    isMaxHeight: false,
};

export default SelectCustom;
