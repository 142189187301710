import { Socket } from "phoenix";

import User from "@/base/project/user.js";

import random from "@/base/lib/random.js";

import actions from "@/base/store/actions.js";

import callbacksByRole from "./callbacks/index.js";


const getCallbacks = (store) => {
    return {
        onStart() {
            store.dispatch(actions.monitors.setSocketMonitorOn());
        },
        onStop() {
            store.dispatch(actions.monitors.setSocketMonitorOff());
        },
        onReconnectedAfterOffline(data) {
            const callbacks = callbacksByRole.getCallbacks(store);

            if (callbacks.onReconnectedAfterOffline) {
                callbacks.onReconnectedAfterOffline(data);
            }
        },
        onUpdate(data) {
            const callbacks = callbacksByRole.getCallbacks(store);

            if (callbacks.onUpdate) {
                callbacks.onUpdate(data);
            }
        },
        onUpdateAll() {
            const callbacks = callbacksByRole.getCallbacks(store);

            if (callbacks.onUpdateAll) {
                callbacks.onUpdateAll();
            }
        },
    };
};

class EventsSocket {
    constructor(eventsURL, store) {
        this.id = random.randomHEX();

        this.eventsURL = eventsURL;
        this.store = store;

        this.state = {
            isRunning: false,
            isAuthorized: false,
            isWentOffline: false,
        };

        this.callbacks = getCallbacks(store);

        this.socket = null;

        this.startStoreMonitor();
    }

    /* --- */

    start() {
        if (this.state.isRunning) {
            return;
        }

        // eslint-disable-next-line no-console
        console.log("[WS]: start monitor");

        this.state.isRunning = true;

        const user = this.store.getState().user;
        const userId = user?.user?.userId || "";
        const session = user?.session || "";

        const socket = new Socket(this.eventsURL, {
            params: {
                session: session,
                id: this.id,
            },
        });

        socket.connect();

        socket.onClose(() => {
            this.state.isWentOffline = true;
        });

        // NOTE: it is a common channel for all users
        const channel = socket.channel(`users:all:${userId}`, {});

        channel.join()
            .receive("ok", (res) => {
                // eslint-disable-next-line no-console
                console.log("[WS]: joined successfully", res);

                if (this.state.isWentOffline) {
                    this.callbacks.onReconnectedAfterOffline();
                    this.state.isWentOffline = false;
                }
            })
            .receive("error", (res) => {
                // eslint-disable-next-line no-console
                console.log("[WS] unable to join", res)
            });

        channel.on("message", (res) => {
            // eslint-disable-next-line no-console
            console.log("[WS] message", res)

            this.callbacks.onUpdate(res);
        });

        this.socket = socket;
    }

    stop() {
        if (!this.state.isRunning) {
            return;
        }

        // eslint-disable-next-line no-console
        console.log("[WS]: stop monitor");

        this.state.isRunning = false;

        if (this.socket && this.socket.disconnect) {
            this.socket.disconnect();
            this.socket = null;
        }
    }

    /* --- */

    changeState(isAuthorized) {
        this.state.isAuthorized = isAuthorized;

        if (isAuthorized) {
            this.start();
        } else {
            this.stop();
        }
    }

    onStoreChange(state) {
        const {
            session,
            isUserLoaded,
            user,
        } = state.user;

        if (!session || !isUserLoaded) {
            this.changeState(false);
            return;
        }

        const isValidRole = User.hasRoleTeacher(user)
            || User.hasRoleDistrictAdmin(user)
            || User.hasRoleStudent(user)

        this.changeState(isValidRole);
    }

    startStoreMonitor() {
        this.store.subscribe(() => {
            const state = this.store.getState();
            this.onStoreChange(state);
        });
    }
}

export default EventsSocket;
